import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../store/auth.actions';
import {
  Auth,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from '@angular/fire/auth';
import { setPersistence } from '@firebase/auth';
import { UserData } from 'src/app/model/UserData';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private store: Store<fromApp.AppState>, private auth: Auth) {}
  private tokenExpirationTimer: any;
  public userData: UserData | null = null;

  login(email: string, password: string) {
    return setPersistence(this.auth, browserLocalPersistence)
      .then(() => signInWithEmailAndPassword(this.auth, email, password))
      .catch((err) => {
        console.error('Login error:', err);
        return err;
      });
  }

  async register(email: string, password: string) {
    await setPersistence(this.auth, browserLocalPersistence);
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  async resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        console.log('Password reset email sent');
      })
      .catch((err) => {
        console.error('Password reset error:', err);
        throw err;
      });
  }

  saveAuth(auth: any): void {
    // const token = auth.user.refreshToken;
    localStorage.setItem('userData', JSON.stringify(auth.userData));
  }

  getToken() {
    // const userData = localStorage.getItem('userData');
    // if (userData) {
    //   const parsedUserdata = JSON.parse(userData);
    //   console.log('parsedUserdata:', parsedUserdata);
    //   return parsedUserdata;
    // } else {
    //   return null;
    // }
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userToken = await user.getIdToken();
        const userData: UserData = {
          uid: user.uid,
          email: user.email,
          refreshToken: user.refreshToken,
          accessToken: userToken,
        };
        this.userData = userData;
      }
    });
    return this.userData;
  }

  cleanAuth() {
    localStorage.removeItem('userData');
    return signOut(this.auth);
  }

  setLogoutTimer(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.store.dispatch(AuthActions.logout());
    }, expirationDuration);
  }

  clearLogoutTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
      this.tokenExpirationTimer = null;
    }
  }
}
