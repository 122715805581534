import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit {
  public fileToUpload: File | null = null;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  private payload: any = {
    headers: {
      contentType: 'multipart/form-data',
    },
  };
  @Input() patientId: string = '';
  @Input() isHm: boolean | null = false;
  public kpiUrl: any;
  public loading: boolean;
  public env = environment;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getKPI();
  }

  //   Get Image

  getKPI() {
    this.loading = true;
    return this.http
      .get(`${environment.NEW_API}/hm/patients/${this.patientId}/kpi`, {
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          this.kpiUrl = null;
          console.error('No Health Meter for this patient');
          this.loading = false;
          return of(null);
        })
      )
      .subscribe((data: any) => {
        if (data !== null) {
          this.loading = false;
          return this.createImageFromBlob(data);
        }
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.kpiUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  //   Upload Image
  uploadImageProfile(file: any) {
    const postUrl: string = `${environment.NEW_API}/hm/patients/${this.patientId}/kpi`;
    const formData = new FormData();
    formData.append('image', file.data, file.data.name);
    return this.http.post(postUrl, formData, this.payload).subscribe((data) => {
      console.log('success', data);
      this.getKPI();
    });
  }

  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadImageProfile(fileParams);
    };
    fileUpload.click();
  }
}
