import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface TabItem {
  title: string;
  icon?: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabs: TabItem[] = [];
  @Output() tabSelected = new EventEmitter<string>();

  activeTab = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    console.log('tabs', this.tabs);
    this.activeTab = this.tabs[0].title;
  }

  sanitizeIcon(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  selectTab(tab: TabItem) {
    this.activeTab = tab.title;
    this.tabSelected.emit(tab.title);
  }
}
