import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import * as RichiesteActions from 'src/app/features/richieste/store/richieste.actions';
import { resetProfile } from 'src/app/features/profilo/store/profilo.actions';
import { filter, mapTo, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { UserCredential } from '@angular/fire/auth';
import { UserData } from 'src/app/model/UserData';
import * as fromApp from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {
  login$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) =>
        this.authService
          .login(action.email, action.password)
          .then(async (res: UserCredential) => {
            const token = await res.user.getIdToken();
            console.log('user:', res.user.toJSON());
            const userData: UserData = {
              uid: res.user.uid,
              email: res.user.email,
              refreshToken: res.user.refreshToken,
              accessToken: token,
            };
            console.log('userData:', userData);

            return AuthActions.loginSuccess({ userData });
          })
          .catch((err) => {
            console.log('err:', err);
            return AuthActions.loginFailed({ error: err });
          })
      )
    )
  );

  register$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      switchMap((action) =>
        this.authService
          .register(action.email, action.password)
          .then(async (res: UserCredential) => {
            const token = await res.user.getIdToken();
            console.log('user:', res.user.toJSON());
            const userData: UserData = {
              uid: res.user.uid,
              email: res.user.email,
              refreshToken: res.user.refreshToken,
              accessToken: token,
            };
            console.log('userData:', userData);
            return AuthActions.registerSuccess({ userData });
          })
          .catch((err) => {
            console.log('err:', err);
            return AuthActions.registerFail({ error: err });
          })
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(async (userData) => {
          this.authService.saveAuth(userData);
        })
        //   map(() => AuthActions.loginRedirect())
      ),
    { dispatch: false }
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerSuccess),
        tap(async (userData) => {
          this.authService.saveAuth(userData);
          //   this.router.navigate(['/privacy']);
        })
        //   map(() => AuthActions.loginRedirect())
      ),
    { dispatch: false }
  );

  autoLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autoLogin),
      mapTo(this.authService.getToken()),
      filter<any>((accessToken) => !!accessToken),
      switchMap((userData) => {
        console.log('userData from autoLogin$:', userData);
        return [AuthActions.loginSuccess({ userData })];
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.authService.cleanAuth();
          this.store.dispatch(RichiesteActions.resetRichieste());
          this.store.dispatch(resetProfile());
          console.log('logout');
        })
      ),
    { dispatch: false }
  );

  authRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginRedirect),
        tap((action) => this.router.navigate(['/appuntamenti']))
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap((action) =>
        this.authService
          .resetPassword(action.email)
          .then(() => {
            return AuthActions.resetPasswordSuccess();
          })
          .catch((err) => {
            return AuthActions.resetPasswordFail({ error: err });
          })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {}
}
