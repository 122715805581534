<ng-container *ngIf="richiesta">
  <div class="single-anamnesi do3-fw600">
    <div class="row do3-gray-text">
      <div class="col-3 pl-4" *ngIf="isFull">
        {{ richiesta.doctor.name }}
      </div>
      <div class="col-3 kpi-survey" *ngIf="!isTriage && isFull">
        {{ handleType(richiesta.specialization.name) }}
      </div>
      <div class="col-9 kpi-survey" *ngIf="!isTriage && !isFull">
        <p>Questionario {{ handleType(richiesta.specialization.name) }}</p>
      </div>
      <div class="col-3" *ngIf="isTriage">
        {{ richiesta.specialization.name }}
      </div>
      <div class="col-3" *ngIf="isFull">
        {{ richiesta.crmNo }}
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center justify-content-between">
          <div *ngIf="isTriage">
            {{ richiesta.slot.from | date : "longDate" }}
          </div>
          <mat-icon (click)="getAnamnesi(richiesta.id)">expand_more</mat-icon>
        </div>
      </div>
    </div>

    <ng-container *ngIf="openPanel">
      <div class="row answers">
        <app-loading-spinner
          class="d-flex justify-content-center"
          *ngIf="isLoading"
        ></app-loading-spinner>
        <div *ngIf="!isLoading" class="col">
          <p *ngFor="let quest of anamnesi.questions; let i = index">
            <span class="do3-fw800 do3-blue-text">
              {{ i + 1 }} - {{ quest.question }}
            </span>
            <br />
            {{ quest.selectedAnswer.description }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
