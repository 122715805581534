import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { Subscription } from 'rxjs';
import { Item } from 'src/app/model/DoctorTriage';

@Component({
  selector: 'app-anamnesi',
  templateUrl: './anamnesi.component.html',
  styleUrls: ['./anamnesi.component.scss'],
})
export class AnamnesiComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  public triages: Item[] = [];

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.storeSub = this.store.select('profile').subscribe((profile) => {
      this.triages = profile.doctorTriage ? profile.doctorTriage.items : [];
      console.log('triages: ', this.triages);
    });

    this.store.dispatch(ProfileActions.fetchTriages());
  }

  ngOnDestroy(): void {
    this.storeSub.unsubscribe();
  }

  status: boolean = true;
  activeAnamnesi(event: Event) {
    this.status = !(event.target as HTMLInputElement).checked;
  }
}
