<!-- Title banner -->
<div class="container">
  <!-- <div class="row banner">
    <div class="col-12">
      <span class="material-icons-outlined">calendar_month</span>
      <span class="text"> Lista pazienti </span>
    </div>
  </div> -->
  <div *ngIf="!patients || patients.data.length === 0">
    <div class="row justify-content-center mt-5">
      <h1 class="text-center no-requests">Nessun paziente da visualizzare.</h1>
    </div>
  </div>
  <div *ngIf="patients.data.length > 0" class="appuntamenti-table align-middle">
    <div class="row th">
      <div class="col"></div>
      <div class="col">Nome</div>
      <div class="col">Cognome</div>
      <div class="col">ID</div>
      <div class="col"></div>
    </div>

    <div *ngIf="patients">
      <ng-container *ngFor="let patient of patients.data">
        <!-- <app-appuntamenti-item
          [richiesta]="richiesta"
          [mode]="mode"
          [listType]="listType"
          (handleClick)="handleDetailClick($event)"
        ></app-appuntamenti-item> -->
        <app-patients-item
          [patient]="patient"
          (handleClick)="handleDetailClick($event)"
        ></app-patients-item>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="patients.meta.pagination.totalPages > 0">
    <!-- Pagination -->
    <div class="row mb-5 justify-content-center">
      <div class="col-auto">
        <div class="pagination">
          <a
            class="page-link"
            (click)="handlePageClick(0)"
            [ngClass]="{
              disabled: page === 0
            }"
          >
            <span class="material-icons-outlined">first_page</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page - 1)"
            [ngClass]="{
              disabled: page === 0
            }"
          >
            <span class="material-icons-outlined">chevron_left</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page + 1)"
            [ngClass]="{
              disabled: page === patients.meta.pagination.totalPages - 1
            }"
          >
            <span class="material-icons-outlined">chevron_right</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(patients.meta.pagination.totalPages - 1)"
            [ngClass]="{
              disabled: page === patients.meta.pagination.totalPages - 1
            }"
          >
            <span class="material-icons-outlined">last_page</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
