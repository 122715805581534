import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ChatClientService,
  ChannelService,
  StreamI18nService,
} from 'stream-chat-angular';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as fromApp from 'src/app/store/app.reducer';
import { fetchUserInfo } from 'src/app/core/header/store/me.actions';
import { ChannelSort } from 'stream-chat';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-stream',
  templateUrl: './get-stream.component.html',
  styleUrls: ['./get-stream.component.scss'],
})
export class GetStreamComponent implements OnInit, OnDestroy {
  private meSub: Subscription;
  private avatarUrl: any;
  private chatUserId: string = '';
  private chatUserToken: string = '';
  private chatDisplayName: string = '';
  public loading: boolean = false;
  public channels: any[] = [];

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    this.translateService.getTranslation('it').subscribe(() => {
      this.streamI18nService.setTranslation('it');
    });
    this.translateService.use('it');
  }

  async ngOnInit() {
    this.getAvatar();
    this.store.dispatch(fetchUserInfo());
    this.meSub = this.store.select('me').subscribe(async (me) => {
      this.chatDisplayName = me.userInfo?.displayName || '';
      this.chatUserId = me.userInfo?.chatInfo?.userId || '';
      this.chatUserToken = me.userInfo?.chatInfo?.userToken || '';

      if (this.chatUserId && this.chatUserToken) {
        console.log('chatUserId:', this.chatUserId);
        console.log('chatUserToken:', this.chatUserToken);

        this.chatService.init(
          environment.streamChat.apiKey,
          this.chatUserId,
          this.chatUserToken
        );

        this.channelService.init({
          type: 'messaging',
          members: { $in: [this.chatUserId] },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.meSub && this.meSub.unsubscribe();
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(`${environment.NEW_API}/profile/image`, { responseType: 'blob' })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
