import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import * as ProfiloPazienteActions from '../../features/profilo-paziente/store/profilo-paziente.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storico',
  templateUrl: './storico.component.html',
  styleUrls: ['./storico.component.scss'],
})
export class StoricoComponent implements OnInit, OnDestroy {
  @Input() patientId: string;
  @Input() isHm: boolean = false;
  public avatarUrl: any;

  private storeSub: Subscription;
  public allRichieste: any[] | null;
  public isLoading: boolean = true;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // console.log(this.patientId, 'from storico')
    this.store.dispatch(
      ProfiloPazienteActions.fetchRichiestePaziente({
        idPatient: this.patientId,
        withTriage: false,
        isHm: this.isHm,
      })
    );
    this.storeSub = this.store.select('profiloPaziente').subscribe((state) => {
      this.allRichieste = state.richieste;
      this.isLoading = !state.richieste;
    });
    this.getAvatar();
  }
  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(`${environment.NEW_API}/profile/image`, { responseType: 'blob' })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
