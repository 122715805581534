import { Action, createReducer, on } from '@ngrx/store';
import { AreaData } from 'src/app/model/Area';
import { AvailableDate, AvailableSlot, SlotDate } from 'src/app/model/Calendar';
import { DoctorData, DoctorSummary } from 'src/app/model/DoctorSummary';
import { Policy } from 'src/app/model/Policy';
import { PrenotazioneResData } from 'src/app/model/PrenotazioneResData';

import * as DocsSummsActions from '../actions/prenotazione.action';

export interface State {
  areas: AreaData[] | null;
  docSumms: DoctorSummary;
  loading: boolean;
  selectedDoctor: DoctorData | null;
  allAvailabilities: AvailableDate[] | null;
  datesLoading: boolean;
  slotsAvailable: SlotDate[] | null;
  slotsLoading: boolean;
  selectedAppointment: SlotDate | null;
  bookingSession: PrenotazioneResData | null;
  policies: Policy | null;
}

const initialState: State = {
  areas: null,
  docSumms: {
    data: [],
    meta: {
      pagination: {
        pageSize: 0,
        pageElements: 0,
        currentPage: 0,
        totalElements: 0,
        totalPages: 0,
        first: false,
        last: false,
        hasNext: false,
        hasPrevious: false,
      },
    },
  },
  loading: false,
  selectedDoctor: null,
  allAvailabilities: null,
  datesLoading: false,
  slotsAvailable: null,
  slotsLoading: false,
  selectedAppointment: null,
  bookingSession: null,
  policies: null,
};

const _docsSummsReducer = createReducer(
  initialState,

  on(DocsSummsActions.fetchAreas, (state) => ({
    ...state,
    loading: true,
  })),

  on(DocsSummsActions.setAreas, (state, action) => ({
    ...state,
    loading: false,
    areas: action.areas,
  })),

  on(DocsSummsActions.fetchDocsSumms, (state) => ({
    ...state,
    loading: true,
  })),

  on(DocsSummsActions.setDocsSumms, (state, action) => ({
    ...state,
    docSumms: action.docSumms,
    loading: false,
  })),

  on(DocsSummsActions.filterDocs, (state, action) => ({
    ...state,
    docSumms: {
      data: [],
      meta: {
        pagination: {
          pageSize: 0,
          pageElements: 0,
          currentPage: 0,
          totalElements: 0,
          totalPages: 0,
          first: false,
          last: false,
          hasNext: false,
          hasPrevious: false,
        },
      },
    },
    loading: true,
  })),

  on(DocsSummsActions.setSelectDoctor, (state, action) => ({
    ...state,
    selectedDoctor: action.selectedDoctor,
  })),

  on(DocsSummsActions.fetchAvailableAppointments, (state, action) => ({
    ...state,
    datesLoading: true,
  })),

  on(DocsSummsActions.setAllAvailabilities, (state, action) => ({
    ...state,
    allAvailabilities: action.allAvailabilities,
    datesLoading: false,
  })),

  on(DocsSummsActions.fetchAllSlots, (state, action) => ({
    ...state,
    slotsLoading: true,
  })),

  on(DocsSummsActions.setAllSlots, (state, action) => ({
    ...state,
    slotsAvailable: action.slotsAvailable,
    slotsLoading: false,
  })),

  on(DocsSummsActions.setSelectedAppointment, (state, action) => ({
    ...state,
    selectedAppointment: action.selectedAppointment,
  })),

  on(DocsSummsActions.prenotazioneResData, (state, action) => ({
    ...state,
    bookingSession: action.data,
  })),

  on(DocsSummsActions.patchPrenotazioneResData, (state, action) => ({
    ...state,
    bookingSession: action.data,
  })),

  on(DocsSummsActions.fetchPolicies, (state, action) => ({
    ...state,
    loading: true,
  })),

  on(DocsSummsActions.fetchPoliciesSuccess, (state, action) => ({
    ...state,
    policies: action.policies,
    loading: false,
  })),

  on(DocsSummsActions.postPolicy, (state, action) => ({
    ...state,
    loading: true,
    policies: null,
  })),

  on(DocsSummsActions.postPolicySuccess, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DocsSummsActions.resetPrenotazioni, (state) => ({
    ...state,
    docSumms: {
      data: [],
      meta: {
        pagination: {
          pageSize: 0,
          pageElements: 0,
          currentPage: 0,
          totalElements: 0,
          totalPages: 0,
          first: false,
          last: false,
          hasNext: false,
          hasPrevious: false,
        },
      },
    },
    loading: false,
    selectedDoctor: null,
    allAvailabilities: null,
    datesLoading: false,
    slotsAvailable: null,
    slotsLoading: false,
    selectedAppointment: null,
    bookingSession: null,
  }))
);

export function docsSummsReducer(state: State | undefined, action: Action) {
  return _docsSummsReducer(state, action);
}
