<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div *ngIf="me && !loading" class="info-profilo">

  <div class="info-profilo-form" *ngIf="!loading">
    <form form #f="ngForm" (ngSubmit)="onSaveProfile(f)" class="row">
      <!-- ERRORS -->
      <div class="col-12">
        <div class="alert alert-danger" role="alert"
          *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
          <h5>Nome</h5>
          <ul>
            <li *ngIf="firstName.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
          <h5>Cognome</h5>
          <ul>
            <li *ngIf="lastName.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert"
          *ngIf="fiscalCode.invalid && fiscalCode.touched">
          <h5>Codice Fiscale</h5>
          <ul>
            <li *ngIf="fiscalCode.errors?.['required']">il campo è obbligatorio</li>
            <li *ngIf="!!fiscalCode.errors?.['pattern']">Codice fiscale errato</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="bornDate.invalid && (bornDate.dirty || bornDate.touched)">
          <h5>Data di Nascita</h5>
          <ul>
            <li *ngIf="bornDate.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="bornCity.invalid && (bornCity.dirty || bornCity.touched)">
          <h5>Luogo di Nascita</h5>
          <ul>
            <li *ngIf="bornCity.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="gender.invalid && (gender.dirty || gender.touched)">
          <h5>Sesso</h5>
          <ul>
            <li *ngIf="gender.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>        

        <div class="alert alert-danger" role="alert" *ngIf="email.invalid && email.touched">
          <h5>Email</h5>
          <ul>
            <li *ngIf="email.errors?.['required']">il campo è obbligatorio</li>
            <li *ngIf="!!email.errors?.['pattern']">Email errata</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="phoneNumber.invalid && phoneNumber.touched">
          <h5>Cellulare</h5>
          <ul>
            <li *ngIf="phoneNumber.errors?.['required']">il campo è obbligatorio</li>
            <li *ngIf="phoneNumber.errors?.['minlength']">Numero di telefono errato</li>

          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="street.invalid && (street.dirty || street.touched)">
          <h5>Indirizzo</h5>
          <ul>
            <li *ngIf="street.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="city.invalid && (city.dirty || city.touched)">
          <h5>Città</h5>
          <ul>
            <li *ngIf="city.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="country.invalid && (country.dirty || country.touched)">
          <h5>Regione</h5>
          <ul>
            <li *ngIf="country.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="zip.invalid && (zip.dirty || zip.touched)">
          <h5>Codice Postale</h5>
          <ul>
            <li *ngIf="zip.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>
      </div>
      <!-- ERRORS END -->

      <div class="form-floating  col-4">
        <input required #firstName="ngModel" name="firstName" class="form-control no-box" id="firstName"
          placeholder="Nome" type="text" [ngModel]="me.firstName">
        <label for="firstName">Nome</label>
      </div>

      <div class="form-floating  col-4">
        <input required #lastName="ngModel" name="lastName" class="form-control no-box" id="lastName"
          placeholder="Cognome" type="text" [ngModel]="me.lastName">
        <label for="lastName">Cognome</label>
      </div>

      <div class="form-floating  col-4">
        <input required #fiscalCode="ngModel" name="fiscalCode" class="form-control no-box" id="fiscalCode"
          placeholder="Codice fiscale" type="text" [ngModel]="me.fiscalCode"
          pattern="^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$">
        <label for="fiscalCode">Codice Fiscale</label>
      </div>

      <div class="form-floating  col-4">
        <input required #bornDate="ngModel" name="bornDate" class="form-control no-box" id="bornDate"
          placeholder="Data" type="date" [ngModel]="me.bornDate">
        <label for="bornDate">Data di Nascita</label>
      </div>

      <div class="form-floating  col-4">
        <input required #bornCity="ngModel" name="bornCity" class="form-control no-box" id="bornCity"
          placeholder="Luogo di Nascita" type="text" [ngModel]="me.bornCity">
        <label for="bornCity">Luogo di Nascita</label>
      </div>

      <div class="form-floating  col-4 ">
        <select required #gender="ngModel" class="form-select no-box do3-fw600 do3-gray-text" name="gender" id="gender" [ngModel]="me.gender">
          <option value="MALE">Uomo</option>
          <option value="FEMALE">Donna</option>
          <option value="OTHER">Preferisco non rispondere</option>
        </select>
        <label for="gender">Sesso</label>
      </div>

      <div class="form-floating  col-6">
        <input required #email="ngModel" name="email" class="form-control no-box" id="email" placeholder="Email"
          [ngModel]="me.email" type="email" pattern='((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})))'>
        <label for="email">Email</label>
      </div>

      <div class="form-floating  col-6">
        <input required #phoneNumber="ngModel" name="phoneNumber" class="form-control no-box" id="phoneNumber"
          placeholder="Cellulare" type="tel" [ngModel]="me.phoneNumber" minlength="10">
        <label for="phoneNumber">Cellulare</label>
      </div>

      <div class="form-floating  col-12">
        <input required #street="ngModel" name="street" class="form-control no-box" id="street" placeholder="Indirizzo"
          type="text" [ngModel]="me.street">
        <label for="street">Indirizzo</label>
      </div>

      <div class="form-floating  col-4">
        <input required #city="ngModel" name="city" class="form-control no-box" id="city" placeholder="Città"
          type="text" [ngModel]="me.city">
        <label for="city">Città</label>
      </div>

      <div class="form-floating  col-4">
        <input required #country="ngModel" name="country" class="form-control no-box" id="country" placeholder="Regione"
          type="text" [ngModel]="me.country">
        <label for="country">Regione</label>
      </div>

      <div class="form-floating  col-4">
        <input required #zip="ngModel" name="zip" class="form-control no-box" id="zip" placeholder="Codice Postale"
          type="number" [ngModel]="me.zip">
        <label for="zip">Codice Postale</label>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <button class="do3-secondary-btn light-blue ml-0" [disabled]="!f.valid" type="submit">SALVA MODIFICHE</button>
      </div>
    </form>
  </div>
</div>
