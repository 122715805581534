import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Me, MeData } from 'src/app/model/Me';
import * as fromApp from '../../store/app.reducer';
import * as MeActions from '../../core/header/store/me.actions';
import { BehaviorSubject, Observable } from 'rxjs';

type Step =
  | 'info'
  | 'curriculum'
  | 'cronologia'
  | 'documenti'
  | 'fatturazione'
  | 'anamnesi'
  | 'privacy';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss'],
})
export class ProfiloComponent implements OnInit, OnDestroy {
  hide = true;
  meSubscription!: Subscription;
  me!: MeData | null;
  activeTab: string = 'info';

  private currentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>(
    'info'
  );

  public currentStep$: Observable<Step> = this.currentStepBs.asObservable();

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(MeActions.fetchMe());
    this.meSubscription = this.store
      .select('me')
      .pipe(map((meState) => meState.me))
      .subscribe((me: MeData | null) => {
        this.me = me;
      });
  }

  ngOnDestroy(): void {
    this.meSubscription.unsubscribe();
  }

  currentTab(currentTab: string) {
    switch (currentTab) {
      case 'infoTab':
        this.activeTab = 'info';
        this.currentStepBs.next('info');
        break;
      case 'curriculumTab':
        this.activeTab = 'curriculum';
        this.currentStepBs.next('curriculum');
        break;
      case 'cronologiaTab':
        this.activeTab = 'cronologia';
        this.currentStepBs.next('cronologia');
        break;
      case 'documentiTab':
        this.activeTab = 'documenti';
        this.currentStepBs.next('documenti');
        break;
      case 'fatturazioneTab':
        this.activeTab = 'fatturazione';
        this.currentStepBs.next('fatturazione');
        break;
      case 'anamnesiTab':
        this.activeTab = 'anamnesi';
        this.currentStepBs.next('anamnesi');
        break;
      case 'privacyTab':
        this.activeTab = 'privacy';
        this.currentStepBs.next('privacy');
        break;
    }
  }
}
