import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { IsLoggedInDirective } from './is-logged-in.directive';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UIModule } from './UI/ui.module';
import { TopperInfoDoctorComponent } from './topper-info-doctor/topper-info-doctor.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { UploadDocComponent } from './upload-doc/upload-doc.component';
import { ChangeAvatarComponent } from './change-avatar/change-avatar.component';
import { AppuntamentiListComponent } from './appuntamenti-list/appuntamenti-list.component';
import { AppuntamentiItemComponent } from './appuntamenti-item/appuntamenti-item.component';
import { MovergyComponent } from './movergy/movergy.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { PatientsItemComponent } from './patients-item/patients-item.component';
import { StoricoComponent } from './storico/storico.component';
import { RefertiComponent } from './referti/referti.component';
import { KpiComponent } from './kpi/kpi.component';
import { SurveysComponent } from './surveys/surveys.component';
import { SingleAnamnesiComponent } from './single-anamnesi/single-anamnesi.component';
import { TabsComponent } from './tabs/tabs.component';
import { RequestsContainerComponent } from './requests-container/requests-container.component';
import { GetStreamComponent } from './get-stream/get-stream.component';
import {
  StreamAutocompleteTextareaModule,
  StreamChatModule,
} from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';
import { KpiInputComponent } from './kpi-input/kpi-input.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    IsLoggedInDirective,
    UserAvatarComponent,
    UserInfoComponent,
    TopperInfoDoctorComponent,
    UploadDocComponent,
    ChangeAvatarComponent,
    AppuntamentiListComponent,
    AppuntamentiItemComponent,
    MovergyComponent,
    PatientsListComponent,
    PatientsItemComponent,
    StoricoComponent,
    RefertiComponent,
    KpiComponent,
    SurveysComponent,
    SingleAnamnesiComponent,
    TabsComponent,
    RequestsContainerComponent,
    GetStreamComponent,
    KpiInputComponent,
  ],
  imports: [
    CommonModule,
    UIModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    AvatarModule,
    StreamChatModule,
    StreamAutocompleteTextareaModule,
    TranslateModule.forChild(),
    FormsModule,
  ],
  exports: [
    LoadingSpinnerComponent,
    IsLoggedInDirective,
    UserAvatarComponent,
    UserInfoComponent,
    UIModule,
    TopperInfoDoctorComponent,
    AvatarModule,
    UploadDocComponent,
    ChangeAvatarComponent,
    AppuntamentiListComponent,
    MovergyComponent,
    PatientsListComponent,
    StoricoComponent,
    RefertiComponent,
    KpiComponent,
    SurveysComponent,
    SingleAnamnesiComponent,
    TabsComponent,
    RequestsContainerComponent,
    GetStreamComponent,
  ],
})
export class SharedModule {}
