<div class="row mt-5 do3-consenso-text">
  <div class="col">
    <p class="fw-bold">Premesso che con il presente modulo</p>
    <p class="mt-2">
      Il/i cliente/i /sono informato/i che: lo scopo dell’erogazione di servizi di
      assistenza sanitaria, tramite il ricorso a tecnologie innovative, quello di offrire assistenza domiciliare a
      distanza e monitorare a distanza e in tempo reale i cambiamenti dei dati sanitari del paziente e utilizzarli in
      un piano di trattamento. <br> Potranno essere usati strumenti conoscitivi (tra i quali il colloquio clinico, test
      diagnostici) per la prevenzione e la diagnosi. Il compenso quello stabilito da IDI S.r.l. sul sito
      https://dottorionline.com e in base ai termini e condizioni di servizio ivi consultabili che il cliente ha già
      accettato durante la procedura di creazione del proprio account personale sulla piattaforma. <br> La durata
      globale
      della prestazione sanitaria nella modalità telemedicina non definibile a priori per motivi clinici. <br> Il/la
      professionista si attiene alle norme del codice deontologico dei Medici Italiani In relazione al trattamento dei
      dati personali ai sensi del Regolamento UE 2016/679 (GDPR), che prevede e rafforza la protezione e il
      trattamento dei dati personali alla luce dei principi di correttezza, liceità, trasparenza, tutela della
      riservatezza e dei diritti dell’interessato in merito ai propri dati, si rinvia all’informativa sulla privacy
      presente sul sito https://dottorionline.com, in contitolari con IDI S.r.l. <br><br> In relazione al trattamento
      dei dati
      relativi alla salute ai sensi del Regolamento UE 2016/679 (GDPR), che prevede e rafforza la protezione e il
      trattamento dei dati personali alla luce dei principi di correttezza, liceità, trasparenza, tutela della
      riservatezza e dei diritti dell’interessato in merito ai propri dati, verranno osservati i seguenti punti:
    </p>
    <ul>
      <li>
        Il/la professionista titolare autonomo del trattamento (i dati sono trattati solo dal Professionista e che non
        vengono condivisi con nessun altro) e dei seguenti dati raccolti per lo svolgimento dell’incarico oggetto di
        questo contratto;
      </li>
      <li>
        I dati personali attinenti alla salute fisica o mentale della persona, compresa la
        prestazione di servizi di assistenza sanitaria, che rivelano informazioni relative allo stato di salute, sono
        raccolti e conservati esclusivamente dal professionista nel corso della terapia, attraverso la richiesta di
        esecuzione di valutazioni, esami, accertamenti diagnostici e ogni altra tipologia di servizio di natura
        professionale connesso con l’esecuzione dell’incarico (ciò sono tutti i dati che verranno forniti fornisci al
        medico nel corso della terapia);
      </li>
    </ul>
    <p>
      Le valutazioni professionali tradotte in dati dal professionista costituiscono
      l’insieme dei dati professionali, trattati secondo tutti i principi del GDPR e gestiti/dovuti secondo quanto
      previsto dal Codice Deontologico dei Medici Italiani (questi sono i dati frutto di elaborazione e valutazione
      del professionista nella sua attività clinica). <br>
      I dati personali ed i dati relativi alla salute potranno essere
      trattati con modalità manuali e informatiche da parte del professionista. In ogni caso saranno adottate tutte le
      procedure idonee a proteggerne la riservatezza, nel rispetto delle norme vigenti e del segreto professionale.
      Saranno utilizzate tutte le adeguate misure di sicurezza al fine di garantire la protezione, la sicurezza,
      l’integrità e l’accessibilità dei dati personali. <br>
      I dati personali ed i dati sanitari verranno conservati solo
      per il tempo necessario al conseguimento delle finalità per le quali sono stati raccolti o per qualsiasi altra
      legittima finalità a essi collegata. <br> I dati personali che non siano più necessari, o per i quali non vi sia
      più
      un presupposto giuridico per la relativa conservazione, verranno anonimizzati irreversibilmente o distrutti in
      modo sicuro. <br>
      I tempi di conservazione, in relazione alle differenti finalità sopra elencate, saranno i seguenti:
    </p>
    <ul>
      <li>
        dati relativi allo stato di salute: saranno conservati unicamente per il periodo di tempo strettamente
        necessario allo svolgimento dell’incarico e al perseguimento delle finalità proprie dell’incarico stesso e
        comunque per un periodo minimo di 5 anni;
      </li>
      <li>
        I dati personali potrebbero dover essere resi accessibili alle
        Autorità Sanitarie e/o Giudiziarie sulla base di precisi doveri di legge;
      </li>
    </ul>
    <p>
      In tutti gli altri casi, ogni comunicazione potrà avvenire solo previo esplicito consenso, e in particolare:
    </p>
    <ul>
      <li>
        dati relativi allo stato di
        salute: verranno resi noti, di regola, solamente all’interessato e solo in presenza di una delega scritta a
        terzi. Verrà adottato ogni mezzo idoneo a prevenire una conoscenza non autorizzata da parte di soggetti terzi
        anche compresenti al conferimento. Potranno essere condivisi, in caso di obblighi di legge, con
        strutture/servizi/ operatori del SSN o altre Autorità pubbliche.
      </li>
    </ul>


    <p>
      L’eventuale lista dei responsabili del
      trattamento e degli altri soggetti cui vengono comunicati i dati può essere visionata a richiesta al persistere
      di talune condizioni, in relazione alle specificità connesse con l’esecuzione dell’incarico, sarà possibile
      all’interessato esercitare i diritti di cui agli articoli da 15 a 22 del GDPR (come ad es. l’accesso ai dati
      personali nonchè la loro rettifica, cancellazione, limitazione del trattamento, copia dei dati personali in un
      formato strutturato di uso comune e leggibile da dispositivo automatico e la trasmissione di tali dati a un
      altro titolare del trattamento). Nel caso di specie sarà onere del professionista verificare la legittimità
      delle richieste fornendo riscontro, di regola, entro 30 giorni.
    </p>
    
    <p class="fw-bold">SI CONVIENE QUANTO SEGUE</p>
    
    <p>
      Il/i cliente/i conferisce/conferiscono al professionista l’incarico di svolgere le seguenti attività:
    </p>
    <ul>
      <li>
        diagnosticare e
        trattare malattie, infortuni ed altri disturbi di salute effettuando controlli di routine e/o visite mediche
      </li>
      <li>
        prescrivere esami (esami di laboratorio, ultrasuoni, elettrocardiogramma, radiografie ecc.)
      </li>
      <li>
        prescrivere e
        somministrare trattamenti appropriati (es. medicinali, operazioni chirurgiche, diete e/o esercizio)
        coerentemente con le diagnosi, ed indirizzando il paziente ad un Medico Specialista per ulteriori visite e/o
        trattamenti se necessario 
      </li>
      <li>
        istruire e offrire consulenza ai pazienti ed ai loro parenti.
      </li>
    </ul>  

    <p>
      Il professionista si
      impegna ad eseguire l’incarico utilizzando strumenti e tecniche validate dal punto di vista scientifico,
      preventivamente illustrate al/i cliente/i e coerenti col modello teorico e metodologico a cui fa riferimento. <br>
       La
      prestazione avrà luogo online in videochiamata nella piattaforma di Dottorionline possibile contattare il
      professionista all'interno dell'area personale della chat. La chat all'interno dell'area personale   criptata ed
      accessibile dal solo professionista e da nessun altro, ivi compreso IDI S.r.l. La chat ha esclusivamente una
      funzione di servizio per l'invio di messaggi istantanei e veloci e non   la sede per svolgere la terapia, che si
      effettua a distanza ma in tempo reale. <br> <br> La disdetta dell'appuntamento prenotato deve essere effettuata almeno 1
      ora prima dell'incontro, nel rispetto del lavoro del Professionista. Al fine di facilitare e monitorare
      l'evoluzione del lavoro clinico nel tempo, il Professionista potrà a sua discrezione raccogliere quanto prodotto
      nel corso del rapporto professionale in forma di cartella clinica, siano essi appunti di sedute, dati ed esiti
      di strumenti di valutazione. I dati verranno conservati, anche per motivi legali e deontologici, per un periodo
      di cinque anni dopo il termine del rapporto. La loro conservazione avviene, secondo quanto previsto dal GDPR in
      merito alla conservazione di dati sensibili, in modalità sicura e senza che terzi possano avervi accesso,
      esclusivamente dal professionista con conservazione presso il proprio domicilio professionale. IDI S.r.l. non
      avrà in condivisione questi dati con il Professionista e quindi non potrà accedervi. <br> <br> L'interruzione del
      trattamento, con conseguente recisione del contratto, può avvenire in qualsiasi momento. In tal caso il/i
      cliente/i s'impegna/s'impegnano a comunicare al professionista la volontà di interruzione e si rende/rendono
      disponibile ad effettuare un ultimo incontro finalizzato alla sintesi del lavoro svolto fino ad allora. <br> Il
      professionista può valutare ed eventualmente proporre l'interruzione del rapporto clinico quando constata che
      non vi sia alcun beneficio dall'intervento e non ragionevolmente prevedibile che ve ne saranno dal
      proseguimento dello stesso. Se richiesto può fornire le informazioni necessarie a ricercare altri e più adatti
      interventi. <br> Per tutto quanto non disciplinato nel presente contratto, si fa rinvio ai Termini e Condizioni d'uso
      del servizio di IDI S.r.l. disponibili sul sito <span class="fw-bold"> https://dottorionline.com</span>
    </p>
    <br>
    <p class="fw-bold">VISTO</p>
    
    <p>
      tutto quanto sopra indicato il/i
      cliente/i compreso tutte le informazioni fornite con la presente informativa sulla prestazione diagnostica di
      telemedicina e sulle modalità di trattamento e conservazione dei dati: <br> acconsente/acconsentono alla esecuzione
      dell’esame nella modalità  telemedicina e con le garanzie espresse nella presente informativa. <br>
      Dichiara/dichiarano di essere stato/i adeguatamente informato/i in modo chiaro e comprensibile sulla metodica
      adottata e acconsente/acconsentono alla registrazione di ogni dato clinico, biologico, audio e video necessario
      ai fini dell’obiettivo da raggiungere nella cartella clinica del professionista medico. <br> Dichiara/dichiarano di
      essere stato/i adeguatamente informato/i sulla metodica della prestazione sanitaria e sull’ eventuale
      indicazione di manifestazioni cliniche stato/sono stati informato/i dei vantaggi e dei rischi relativi alla
      visita in oggetto, sia quelli correlati allo specifico ricorso a strumenti digitali ed alla distanza fisica tra
      medico e paziente o all’impossibilità di un controllo di una sintomatologia non in presenza,
      dell’impossibilità di una visita completa e di un intervento immediato in caso di urgenza stato/sono stati
      informato/i del fatto che esistono metodi alternativi per eseguire la visita ed in particolare che è possibile
      rivolgersi a strutture del servizio sanitario nazionale, aziende convenzionate con il servizio sanitario e/o
      aziende e laboratori privati che erogano la prestazione con metodi tradizionali stato/sono stati informato/i che
      nel caso di mancato raggiungimento degli obiettivi relativi alla prestazione sanitaria in oggetto dovuti a
      motivi tecnici ed altre ragioni, la stessa prestazione medica verrà riprogrammata stato/sono stati informato/i
      di quanto segue: i medici che referteranno i dati non saranno fisicamente nella stessa stanza con me/noi. I
      medici o il personale infermieristico e i tecnici potranno assistere all’esame per ogni fase necessaria alla
      buona riuscita. /Sono, in ogni caso informato/i che la responsabilità dell’esame dipenderà dai medici
      specialisti refertatori e dall’eventuale struttura sanitaria dalla quale questo dipende. <br> Autorizza/autorizzano
      il medico incaricato ad utilizzare i dati sanitari, ma solo in forma anonima ed aggregata, al fine di studio
      scientifico, controllo economico delle prestazioni, elaborazioni di dati epidemiologici e pubblicazioni
      scientifiche o congressuali ed in ogni caso, nel rispetto delle normative vigenti in materia.
      autorizza/autorizzano, inoltre, alla trasmissione dei dati, delle fatture e dei referti, se richiesto, alle
      compagnie di assicurazione che copriranno i costi delle prestazioni e che verrà indicato esplicitamente, o nel
      caso il presente accordo sia finalizzato all’erogazione, in tutto o in parte, al servizio sanitario nazionale. <br>
      Dichiara/dichiarano che la procedura indicata   stata correttamente spiegata ed ha/hanno ottenuto risposta alle
      domande che sono state poste sono informato/i che in qualsiasi momento posso revocare il mio consenso alla
      prestazione sanitaria salvo che l’atto non sia più arrestabile. 
    </p>
      <p>
        <span class="fw-bold">VISTO E COMPRESO</span>
        
       tutto quanto sopra indicato
      avendo ricevuto apposita informativa professionale e informazioni adeguate in relazione a costi, fini e modalità
      della stessa, esprime il proprio libero consenso, barrando la casella di seguito, alla prestazione suindicata.
    </p>
  </div>
  <!-- <div class="row">
    <p>Ho letto e compreso le condizioni del consenso informato del dottore {{doctor}}</p>
  </div> -->

</div>