<div class="row table-row">
  <div class="col">
    <div class="img-profile-container medium big-border mx-0">
      <img
        class="img-profile pointer"
        [src]="avatarUrl"
        alt="{{ richiesta.patient.name }}"
        (click)="goToPazienteProfile(richiesta.patient.id)"
      />
    </div>
  </div>
  <div class="col-2">
    {{
      richiesta.patient.name.length > 17
        ? (richiesta.patient.name | slice : 0 : 17) + ".."
        : richiesta.patient.name
    }}
  </div>
  <div class="col" *ngIf="listType == 'today'">
    {{ richiesta.slot.from | date : "shortTime" }}
  </div>
  <div
    class="col-2"
    *ngIf="
      listType === 'future' || listType === 'closed' || listType === 'open'
    "
  >
    {{ richiesta.slot.from | date : "longDate" }} <br />
    Ore:
    {{ richiesta.slot.from | date : "shortTime" }}
  </div>
  <div class="col-2">{{ richiesta.specialization.description }}</div>
  <div
    class="col"
    [ngClass]="richiesta.hasInformedConsent === true ? 'green' : 'red'"
  >
    <span>
      {{ richiesta.hasInformedConsent === true ? "Firmato" : "Da firmare" }}
    </span>
  </div>
  <div class="col">
    <span class="no-wrap">
      <ng-container *ngIf="richiesta.needTriage && richiesta.hasTriage">
        <span class="green"> Compilata </span>
      </ng-container>
      <ng-container *ngIf="richiesta.needTriage && !richiesta.hasTriage">
        <span class="red"> Da compilare </span>
      </ng-container>
      <ng-container *ngIf="!richiesta.needTriage"> Non richiesta </ng-container>
    </span>
  </div>
  <div *ngIf="richiesta.documentCount == 0" class="col">
    Non presente
  </div>
  <div class="no-wrap" *ngIf="richiesta.documentCount == 1" class="col">
    {{ richiesta.documentCount }} presente
  </div>
  <div class="no-wrap" *ngIf="richiesta.documentCount > 1" class="col">
    {{ richiesta.documentCount }} presenti
  </div>

  <div class="col-2 last">
    <button
      type="button"
      class="do3-secondary-btn lightBlue text-uppercase w-auto me-2"
      (click)="handleDetailClick(richiesta)"
    >
      {{ mode === "appuntamenti" ? "Videoconsulto" : "Vedi dettagli" }}
    </button>
  </div>
</div>
