<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<section *ngIf="me && !loading" class="curriculum">
  <div class="info-cv">
    <div class="d-flex justify-content-between">
      <h3>Curriculum</h3>
      <!--<mat-icon class="material-symbols-outlined" *ngIf="( ! isEditing )" (click)="edit('cv')">edit</mat-icon>-->
    </div>
    <!--<ng-container *ngIf="isEditing" class="editor">
      <textarea class="form-control mb-3" rows="5" [(ngModel)]="me.cv" (keydown.escape)="cancel('cv')"></textarea>
      <button type="button" class="btn btn-success me-3">Salva</button>
      <button type="button" class="btn btn-danger"
        (click)="cancel('cv')"
        (keydown.enter)="cancel('cv')"
        tabindex="0">
        Cancella
      </button>
    </ng-container>-->
    <p *ngIf="( ! isEditing )" [innerHtml]="me.cv">
      {{me.cv}}
    </p>
  </div>
  <div class="info-istruzione">
    <div class="d-flex justify-content-between">
      <h3>Istruzione e Formazione</h3>
     <!-- <mat-icon class="material-symbols-outlined" *ngIf="( ! isEditingInstructor )" (click)="edit('instructor')">edit</mat-icon> -->
    </div>
    <ul>
      <li *ngFor="let formazione of me.education">
       <div *ngIf="( ! isEditingInstructor )">{{ formazione.title }} {{ formazione.description }} {{ formazione.period }} </div>
       <!-- <ng-container *ngIf="isEditingInstructor" id="instructor" class="editor">
          <input type="text" class="form-control mb-3" [(ngModel)]="formazione.title" (keydown.escape)="cancel('instructor')" />
          <button type="button" class="btn btn-success me-3">Salva</button>
          <button type="button" class="btn btn-danger"
                  (click)="cancel('instructor')"
                  (keydown.enter)="cancel('instructor')"
                  tabindex="0">
            Cancella
          </button>
        </ng-container> -->
      </li>
    </ul>
  </div>
  <div class="info-pubblicazioni">
    <div class="d-flex justify-content-between">
      <h3>Pubblicazioni</h3>
      <!--<mat-icon class="material-symbols-outlined">edit</mat-icon>-->
    </div>
    <ul>
      <li *ngFor="let pubblicazione of me.publication">
        {{ pubblicazione.description }} {{ pubblicazione.period }}
      </li>
    </ul>
  </div>
  <div class="info-languages">
    <div class="d-flex justify-content-between">
      <h3>Lingue</h3>
     <!-- <mat-icon class="material-symbols-outlined">edit</mat-icon> -->
    </div>
    <ul>
      <li *ngFor="let lingua of me.languages">
        {{ lingua }}
      </li>
    </ul>
  </div>
  <!--<div class="info-enti">
    <div class="d-flex justify-content-between">
      <h3>Enti e società scientifiche</h3>
    </div>
    <ul>
      <li *ngFor="let lingua of me.languages">
        {{ lingua }}
      </li>
    </ul>
  </div>-->
</section>
