<div class="plus-button" (click)="onClick()">
  <div class="btn do3-secondary-btn light-blue">
    <ng-content></ng-content>
  </div>
  <input
    type="file"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    multiple="multiple"
    accept="image/*"
    style="display: none"
  />
</div>
