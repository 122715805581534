import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as fromApp from '../store/app.reducer';

@Directive({
  selector: '[appIsLoggedIn]',
})
export class IsLoggedInDirective {
  subscription!: Subscription;
  private destroy$ = new Subject();

  constructor(
    private template: TemplateRef<any>,
    private view: ViewContainerRef,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    console.log('hello from is-logged-in.directive.ts');

    this.subscription = this.store
      .pipe(select('auth'), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((authState) => {
        console.log('authState from is logged in directive:', authState);

        if (authState.userData?.accessToken) {
          this.view.createEmbeddedView(this.template);
        } else {
          this.view.clear();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }
}
