import { createAction, props } from '@ngrx/store';
import { DoctorTriage } from 'src/app/model/DoctorTriage';
import { MeData } from 'src/app/model/Me';
import { Policy } from 'src/app/model/Policy';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';

export const fetchDocuments = createAction('[Documents] Fetch Documents');

export const fetchDocumentsSuccess = createAction(
  '[Documents] Fetch Documents Success',
  props<{ documents: ProfiloDocumentDatum[] }>()
);

export const fetchDocumentsFail = createAction(
  '[Documents] Fetch Documents Fail',
  props<{ error: any }>()
);

export const uploadDocument = createAction(
  '[Documents] Upload Document',
  props<{ document: any }>()
);

export const fetchPoliciesProfile = createAction('[Policies] Fetch Policies');

export const fetchPoliciesSuccess = createAction(
  '[Policies] Fetch Policies Success',
  props<{ policies: Policy }>()
);

export const fetchPoliciesFail = createAction(
  '[Policies] Fetch Policies Fail',
  props<{ error: any }>()
);

export const postPolicyProfile = createAction(
  '[Policies] Post Policy Profile',
  props<{ policy: any }>()
);

export const changeProfilePic = createAction(
  '[Profile] Change Profile Pic',
  props<{ profilePic: any }>()
);

export const fetchTriages = createAction('[Triages] Fetch Triages');

export const fetchTriagesSuccess = createAction(
  '[Triages] Fetch Triages Success',
  props<{ triages: DoctorTriage }>()
);

export const fetchTriagesFail = createAction(
  '[Triages] Fetch Triages Fail',
  props<{ error: any }>()
);

export const resetProfile = createAction('[Profile] Reset Profile');

export const fetchCv = createAction('[Curriculum] Fetch Curriculum');
