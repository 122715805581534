import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as MeActions from './me.actions';
import { Me } from 'src/app/model/Me';
import { UserInfo } from 'src/app/model/UserInfo';

@Injectable()
export class MeEffects {
  fetchMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.fetchMe),
      switchMap((action) => {
        console.log('fetching me');
        return this.http.get<Me>(environment.NEW_API + '/profile');
      }),
      map((me) => {
        console.log('Me from effects:', me.data);
        return MeActions.setMe({ me: me.data });
      })
    )
  );

  modifyMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.updateMe),
      switchMap((action) => {
        console.log('update me');
        return this.http.put<Me>(environment.NEW_API + '/profile', action.me);
      }),
      map((me) => {
        // console.log('Updated me from effects:', JSON.stringify(me));
        return MeActions.setMe({ me: me.data });
      })
    )
  );

  fetchUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.fetchUserInfo),
      switchMap(() => {
        console.log('fetching user info');
        return this.http.get<UserInfo>(environment.NEW_API + '/user/info');
      }),
      map((userInfo) => {
        console.log('User info from effects:', userInfo);
        return MeActions.fetchUserInfoSuccess({ userInfo: userInfo });
      }),
      catchError((error) => {
        console.log('Error from effects:', error);
        return of(MeActions.fetchUserInfoFail({ error: error }));
      })
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
