import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient, PatientDatum } from 'src/app/model/Patient';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  @Input() patients: Patient;
  @Input() page: number = 0;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() changePage: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleDetailClick(patient: PatientDatum) {
    this.onClick.emit(patient);
  }

  handlePageClick(page: any) {
    this.page = page;
    this.changePage.emit(this.page);
  }
}
