import { Action, createReducer, on } from '@ngrx/store';
import { CalendarRequestDatum } from 'src/app/model/CalendarRequest';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import { RichiestaDetailDatum } from 'src/app/model/RichiestaDetail';
import { TriageData } from 'src/app/model/Triage';
import * as RichiesteActions from './richieste.actions';

export interface State {
  allRichieste: Richiesta | null;
  todayRichieste: Richiesta | null;
  futureRichieste: Richiesta | null;
  pastOpenRichieste: Richiesta | null;
  pastClosedRichieste: Richiesta | null;
  calendarRichieste: CalendarRequestDatum[];
  loading: boolean;
  triageLoading: boolean;
  documentsLoading: boolean;
  selectedRichiesta: RichiestaDetailDatum | null;
  selectedRichiestaDocuments: any[];
  error: boolean;
  errorMessage: any;
  triage: TriageData | null;
  roomUrl: string | null;
  movergyDisplay: any;
  movergyDisplayLoading: boolean;
}

const initialState: State = {
  allRichieste: null,
  todayRichieste: null,
  futureRichieste: null,
  pastOpenRichieste: null,
  pastClosedRichieste: null,
  calendarRichieste: [],
  loading: false,
  triageLoading: false,
  documentsLoading: false,
  selectedRichiesta: null,
  selectedRichiestaDocuments: [],
  error: false,
  errorMessage: null,
  triage: null,
  roomUrl: null,
  movergyDisplay: null,
  movergyDisplayLoading: false,
};

const _richiesteReducer = createReducer(
  initialState,

  on(RichiesteActions.fetchAllRichieste, (state) => ({
    ...state,
    allRichieste: null,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchAllRichiesteSuccess, (state, action) => ({
    ...state,
    allRichieste: action.richieste,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchAllRichiesteFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchRichiesteDetails, (state) => ({
    ...state,
    loading: true,
  })),

  on(RichiesteActions.fetchRichiestaDetailsSuccess, (state, action) => ({
    ...state,
    selectedRichiesta: action.richiesta,
    selectedRichiestaDocuments: [],
    loading: false,
    error: false,
    errorMessage: null,
  })),

  //   on(RichiesteActions.deleteRichiestaSuccess, (state, action) => {
  //     return {
  //       ...state,
  //       allRichieste: state.allRichieste?.data.filter((p) => p.id !== action.id),
  //     };
  //   }),

  on(RichiesteActions.fetchTriage, (state) => ({
    ...state,
    triageLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchTriageSuccess, (state, action) => ({
    ...state,
    triage: action.triage,
    triageLoading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchTriageFailed, (state, action) => ({
    ...state,
    triageLoading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.postTriage, (state) => ({
    ...state,
    triageLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.postTriageSuccess, (state, action) => ({
    ...state,
    triageLoading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.postTriageFailed, (state, action) => ({
    ...state,
    triageLoading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchRichiestaDocuments, (state) => ({
    ...state,
    documentsLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchRichiestaDocumentsSuccess, (state, action) => ({
    ...state,
    selectedRichiestaDocuments: action.documents,
    documentsLoading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchRichiestaDocumentsFailed, (state, action) => ({
    ...state,
    documentsLoading: false,
    error: true,
    errorMessage: action.error,
  })),

  // on(RichiesteActions.fetchRichiestaMeeting, (state) => ({
  // ...state,
  // loading: true,
  // error: false,
  // errorMessage: null,
  // })),

  on(RichiesteActions.fetchRichiestaMeetingSuccess, (state, action) => ({
    ...state,
    roomUrl: action.meeting,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchRichiestaMeetingFailed, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchCalendarRequests, (state) => ({
    ...state,
    loading: true,
  })),

  on(RichiesteActions.fetchCalendarRequestsSuccess, (state, action) => ({
    ...state,
    calendarRichieste: action.requests,
    loading: false,
  })),

  on(RichiesteActions.fetchCalendarRequestsFailed, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchTodayRequests, (state) => ({
    ...state,
    todayRichieste: null,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchTodayRequestsSuccess, (state, action) => ({
    ...state,
    todayRichieste: action.richieste,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchTodayRequestsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchFutureRequests, (state) => ({
    ...state,
    futureRichieste: null,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchFutureRequestsSuccess, (state, action) => ({
    ...state,
    futureRichieste: action.richieste,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchFutureRequestsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchPastOpenRequests, (state) => ({
    ...state,
    pastOpenRichieste: null,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchPastOpenRequestsSuccess, (state, action) => ({
    ...state,
    pastOpenRichieste: action.richieste,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchPastOpenRequestsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchClosedRequests, (state) => ({
    ...state,
    pastClosedRichieste: null,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchClosedRequestsSuccess, (state, action) => ({
    ...state,
    pastClosedRichieste: action.richieste,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchClosedRequestsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.resetRichieste, (state) => ({
    ...state,
    allRichieste: null,
    todayRichieste: null,
    futureRichieste: null,
    pastOpenRichieste: null,
    pastClosedRichieste: null,
    calendarRichieste: [],
    loading: false,
    triageLoading: false,
    documentsLoading: false,
    selectedRichiesta: null,
    selectedRichiestaDocuments: [],
    error: false,
    errorMessage: null,
    triage: null,
    roomUrl: null,
    movergyDisplay: null,
    movergyDisplayLoading: false,
  })),

  on(RichiesteActions.postNote, (state) => ({
    ...state,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.postNoteSuccess, (state, action) => ({
    ...state,
    note: action.note,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.postNoteFailed, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.movergyDisplay, (state) => ({
    ...state,
    movergyDisplay: null,
    movergyDisplayLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.movergyDisplaySuccess, (state, action) => ({
    ...state,
    movergyDisplay: action.movergyDisplay,
    movergyDisplayLoading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.movergyDisplayFailed, (state, action) => ({
    ...state,
    movergyDisplayLoading: false,
    error: true,
    errorMessage: action.error,
  }))
);

export function richiesteReducer(state: State | undefined, action: Action) {
  return _richiesteReducer(state, action);
}
