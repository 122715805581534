import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfiloRoutingModule } from './profilo-routing.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ConsensoModule } from '../consenso/consenso.module';
import { MatIconModule } from '@angular/material/icon';
import { CronologiaComponent } from './cronologia/cronologia.component';
import { DocumentiComponent } from './documenti/documenti.component';
import { FatturazioneComponent } from './fatturazione/fatturazione.component';
import { AnamnesiComponent } from './anamnesi/anamnesi.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { InfoProfiloComponent } from './info-profilo/info-profilo.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfiloComponent } from './profilo.component';
import { FormsModule } from '@angular/forms';
import { DocumentBoxComponent } from './documenti/document-box/document-box.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { InputEditComponent } from './curriculum/components/input-edit/input-edit.component';
import { MovergyPageComponent } from './movergy-page/movergy-page.component';
import { AnamnesiListComponent } from './anamnesi/anamnesi-list/anamnesi-list.component';
import { SingleAnamnesiComponent } from './anamnesi/anamnesi-list/single-anamnesi/single-anamnesi.component';
@NgModule({
  declarations: [
    ProfiloComponent,
    CronologiaComponent,
    DocumentiComponent,
    FatturazioneComponent,
    AnamnesiComponent,
    AnamnesiListComponent,
    SingleAnamnesiComponent,
    PrivacyComponent,
    InfoProfiloComponent,
    DocumentBoxComponent,
    CurriculumComponent,
    InputEditComponent,
    MovergyPageComponent,
  ],
  imports: [
    CommonModule,
    ProfiloRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    ConsensoModule,
    MatIconModule,
    SharedModule,
    FormsModule,
  ],
  exports: [DocumentiComponent],
})
export class ProfiloModule {}
