import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RichiestaDatum } from 'src/app/model/Richiesta';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appuntamenti-item',
  templateUrl: './appuntamenti-item.component.html',
  styleUrls: ['./appuntamenti-item.component.scss'],
})
export class AppuntamentiItemComponent implements OnInit {
  @Input() richiesta: RichiestaDatum;
  @Input() mode: 'appuntamenti' | 'storico';
  @Input() listType: 'today' | 'future' | 'past' | 'all' | 'open' | 'closed' =
    'today';
  @Output() handleClick: EventEmitter<RichiestaDatum> = new EventEmitter();
  public avatarUrl: any;
  public dateDisplay: any;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.getAvatar();

    switch (this.listType) {
      case 'today':
        this.dateDisplay = new Date();
        break;

      case 'future':
        this.dateDisplay = 'Appuntamenti in programma';
        break;

      default:
        break;
    }
  }

  handleDetailClick(richiesta: RichiestaDatum) {
    this.handleClick.emit(richiesta);
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(
        `${environment.NEW_API}/patients/${this.richiesta.patient.id}/image`,
        { responseType: 'blob' }
      )
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  goToPazienteProfile(patientId: any): void {
    this.router.navigate(['/profilo-paziente', patientId]);
  }
}
