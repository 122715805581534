import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';

@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.scss'],
})
export class DocumentiComponent implements OnInit, OnDestroy {
  private storeSubscription: Subscription;
  public documentsFromStore: ProfiloDocumentDatum[];
  public loading: boolean = false;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(ProfileActions.fetchDocuments());
    this.storeSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        this.documentsFromStore = profile.documents;
        this.loading = profile.loading;
        console.log('documents from store', this.documentsFromStore);
      });
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }
}
