<!-- Title banner -->
<div class="container">
  <div class="row banner">
    <div class="col-12">
      <span *ngIf="
          listType == 'today' || listType == 'future' || listType == 'closed'
        " class="material-icons-outlined">calendar_month</span>
      <span *ngIf="listType == 'open'" class="material-icons-outlined">calendar_month</span>
      <span *ngIf="listType == 'today'" class="text">Appuntamenti di {{ dateDisplay | date: "EEEE d MMMM y" }}
      </span>
      <span *ngIf="listType !== 'today'" class="text">{{ dateDisplay }} </span>
      <span *ngIf="listType == 'open'" class="text">Pazienti da refertare
      </span>
      <span *ngIf="listType == 'closed'" class="text">Appuntamenti archiviati
      </span>
    </div>
  </div>
  <div *ngIf="!richieste || richieste.data.length === 0">
    <div class="row justify-content-center mt-5">
      <h1 class="text-center no-requests">
        Al momento non hai alcuna prenotazione.
      </h1>
    </div>
  </div>
  <div *ngIf="richieste.data.length > 0" class="appuntamenti-table align-middle">
    <div class="row th">
      <div class="col"></div>
      <div class="col-2">Nome</div>
      <div class="col" *ngIf="listType === 'today'">Ora</div>
      <div class="col-2" *ngIf="
          listType === 'future' || listType === 'closed' || listType === 'open'
        ">
        Data visita/ora
      </div>
      <div class="col-2">Tipo di visita</div>
      <div class="col"><span>Consenso</span></div>
      <div class="col"><span>Questionario</span></div>
      <div class="col"><span>Documenti</span></div>
      <div class="col-2"></div>
    </div>

    <div *ngIf="richieste">
      <ng-container *ngFor="let richiesta of richiesteData">
        <app-appuntamenti-item [richiesta]="richiesta" [mode]="mode" [listType]="listType"
          (handleClick)="handleDetailClick($event)"></app-appuntamenti-item>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="richiesteMeta.pagination.totalPages > 0">
    <!-- Pagination -->
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="pagination">
          <a class="page-link" (click)="handlePageClick(0)" [ngClass]="{
              disabled: page === 0
            }">
            <span class="material-icons-outlined">first_page</span>
          </a>
          <a class="page-link" (click)="handlePageClick(page - 1)" [ngClass]="{
              disabled: page === 0
            }">
            <span class="material-icons-outlined">chevron_left</span>
          </a>
          <a class="page-link" (click)="handlePageClick(page + 1)" [ngClass]="{
              disabled: page === richiesteMeta.pagination.totalPages - 1
            }">
            <span class="material-icons-outlined">chevron_right</span>
          </a>
          <a class="page-link" (click)="handlePageClick(richiesteMeta.pagination.totalPages - 1)" [ngClass]="{
              disabled: page === richiesteMeta.pagination.totalPages - 1
            }">
            <span class="material-icons-outlined">last_page</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>