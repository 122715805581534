import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-document-box',
  templateUrl: './document-box.component.html',
  styleUrls: ['./document-box.component.scss'],
})
export class DocumentBoxComponent implements OnInit {
  @Input() doc: ProfiloDocumentDatum;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  parseDocType(docType: string) {
    switch (docType) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpg';
      case 'document/doc':
        return 'doc';
      case 'application/pdf':
        return 'pdf';
      case 'other':
        return 'etc';
      default:
        return 'etc';
    }
  }

  downloadDocument(doc: ProfiloDocumentDatum) {
    console.log('download document', doc);
    const url = `${environment.NEW_API}/documents/${doc.id}/content/`;

    return this.http
      .get(url, { responseType: 'blob', headers: { Accept: doc.contentType } })
      .subscribe((blob) => {
        saveAs(blob, doc.name);
      });
  }
}
