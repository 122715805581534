<div class="row privacy">
  <div class="col-12">
    <h1 class="do3-fw600 do3-blue-text">
      Ecco le tue preferenze per il trattamento dei dati personali. <br /><b
        >Scarica il documento</b
      >
      per visualizzare tutte le informazioni
    </h1>
  </div>

  <div class="col-12 do3-gray-text">
    <div class="row align-items-center privacy-container">
      <div class="privacy-item col-9">
        <p>
          Ho letto e compreso le
          <a routerLink="/terminiecondizioni" class="do3-fw800"
            >Informazioni pre-contrattuali.</a
          >
        </p>
      </div>

      <!-- <div class="col-3">
        <a href="#">
          <mat-icon class="material-symbols-outlined">file_download</mat-icon>
        </a>
      </div> -->
    </div>

    <div class="row align-items-center privacy-container">
      <div class="privacy-item col-9">
        <p>
          Ho letto e compreso l'informativa
          <a routerLink="/privacypolicy" class="do3-fw800">Privacy Policy</a> e
          <a routerLink="/cookie-policy" class="do3-fw800">Cookie Policy</a> ed
          ho inteso che i miei dati saranno inviati al centro servizi per
          l’erogazione delle prestazioni sanitaria.
        </p>
      </div>

      <!-- <div class="col-3">
        <a href="#">
          <mat-icon class="material-symbols-outlined">file_download</mat-icon>
        </a>
      </div> -->
    </div>

    <div
      class="row align-items-center privacy-container"
      *ngIf="policies.data[2]"
    >
      <div class="privacy-item col-7">
        <p>
          Ho letto e compreso le
          <span class="do3-fw800">caratteristiche principali</span>, i
          <span class="do3-fw800">termini del programma di Affiliazione</span> e
          i <span class="do3-fw800">servizi</span> di dottorionline.com.
        </p>
      </div>
      <p class="col-3 data-text">
        Data accettazione:
        {{ policies.data[2].lastUpdate | date : "shortDate" }}
      </p>
      <div class="col-1">
        <div class="toggle-button-cover">
          <div class="button-cover">
            <div class="button r" id="button-1">
              <input
                [ngModel]="policies.data[2].accepted"
                type="checkbox"
                class="checkbox"
                name="privacy3"
                id="privacy3"
                [required]="policies.data[2].mandatory"
                [disabled]="policies.data[2].mandatory"
              />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1">
        <a
          [href]="
            getPolicyLink(
              policies.data[2].policyId,
              policies.data[2].resourceId
            )
          "
        >
          <mat-icon>save_alt</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
