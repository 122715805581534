<app-loading-spinner
  class="row d-flex justify-content-center"
  *ngIf="isLoading"
></app-loading-spinner>

<section
  class="storico"
  *ngIf="!isLoading && allRichieste && allRichieste.length > 0"
>
  <div class="row do3-fw800 do3-blue-text header">
    <!--<div class="col-1"></div>-->
    <div class="col-10">
      <div class="row">
        <div class="col-1">ID</div>
        <div class="col-3">Nome</div>
        <div class="col-3">Data visita</div>
        <div class="col-3">Tipologia</div>
        <div class="col-2">Stato</div>
      </div>
    </div>
    <div class="col-2"></div>
  </div>

  <div *ngFor="let richiesta of allRichieste">
    <div class="storico-box">
      <div class="row align-items-center">
        <!--<div class="col-1">
          <div class="box">
            <div class="box-content" *ngIf="!isHm">
              <div class="avatar-border">
                <ngx-avatars
                  alt="avatar"
                  [textSizeRatio]="3"
                  bgColor="#FF3D77 "
                  fgColor="white"
                  class="circle my-avatar"
                  [name]="richiesta.doctor.name"
                  size="50"
                  [round]="true"
                  [src]="avatarUrl"
                >
                </ngx-avatars>
              </div>
            </div>
          </div>
        </div>-->
        <div class="col-10">
          <div class="row">
            <div class="col-1">{{ richiesta.crmNo }}</div>
            <div class="col-3">{{ richiesta.doctor.name }}</div>
            <div class="col-3">
              {{ richiesta.slot.from | date : "longDate" }}
            </div>
            <div class="col-3">{{ richiesta.specialization.description }}</div>
            <div class="col-2">
              <span class="badge text-white"
                    [ngClass]="{
                      'bg-success': richiesta.status === 'OPEN',
                      'bg-warning': richiesta.status === 'CLOSED',
                      'bg-danger': richiesta.status === 'DELETED'
                    }">{{ richiesta.status }}</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex align-items-center">
          <button type="button" class="btn" *ngIf="richiesta.status !== 'DELETED'">
            <a [href]="'detail/' + richiesta.id">Dettaglio</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="(!isLoading && !allRichieste) || allRichieste?.length == 0">
  <h1 class="d-flex justify-content-center m-5 do3-fw600">
    Al momento non ci sono appuntamenti.
  </h1>
</section>
