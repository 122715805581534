<div class="document-box">
  <div class="row align-items-center">
    <div class="col-1">
      <div class="box" [ngClass]="parseDocType(doc.contentType)">
        <div class="box-content">
          {{ parseDocType(doc.contentType) }}
        </div>
      </div>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-3">{{ doc.name }}</div>
        <div class="col-3">{{ doc.bytes }}</div>
        <div class="col-3">{{ doc.author.name }}</div>
        <div class="col-3">{{ doc.dateTime | date: "d MMMM y - HH:mm" }}</div>
      </div>
    </div>
    <div class="col-1 d-flex align-items-center cursor">
      <div (click)="downloadDocument(doc)">
        <mat-icon class="do3-lightblue-text"> save_alt </mat-icon>
      </div>
      <mat-icon class="dots">more_vert</mat-icon>
    </div>
  </div>
</div>
