<div class="container mt60 assistenza">
  <div class="row">
    <div class="col-sm-4">
      <mat-list role="list">
        <mat-list-item
          role="listitem"
          (click)="
            viewMode = 'funzioni_disponibili';
            headerTitle = 'Funzioni Disponibili';
            headerDescription =
              'La navigazione in piattaforma ti crea qualche disagio? Niente paura. Consulta la nostra sezione dedicata per un supporto veloce.'
          "
          [ngClass]="{ activeLink: viewMode == 'funzioni_disponibili' }"
          >Funzioni Disponibili</mat-list-item
        >
        <mat-list-item
          role="listitem"
          (click)="
            viewMode = 'televisita';
            headerTitle = 'Videoconsulto';
            headerDescription =
              'Il servizio di Videoconsulto ti crea qualche incertezza? Prova a consultare la nostra sezione di riferimento per maggiori dettagli.'
          "
          [ngClass]="{ activeLink: viewMode == 'televisita' }"
          >Videoconsulto</mat-list-item
        >
      </mat-list>
    </div>
    <div class="col-sm-8">
      <div class="col-12">
        <h2 class="mat-headline fw-bold">Come possiamo aiutarti?</h2>
        <p>
          Cerca tra gli argomenti popolari oppure contatta la nostra assistenza
          tecnica in chat.
        </p>
      </div>
      <div class="colored p-3 rounded my-3" id="header-faq">
        <h3 class="fw-bold mb-1">{{ headerTitle }}</h3>
        <p>{{ headerDescription }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-8">
      <div [ngSwitch]="viewMode">
        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'funzioni_disponibili'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.funzioni_disponibili"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ funzioni.description }}</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'televisita'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.televisita"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ funzioni.description }}</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <!--<mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'agenda'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.agenda"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ funzioni.description }}</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>-->
      </div>
      <a href="../../../../assets/docs/ManualeMedico.pdf" target="_blank"><button type="button" class="do3-primary-btn blue rounded-pill w-auto text-uppercase do3-fw800">scarica guida</button></a>
    </div>
  </div>
</div>
