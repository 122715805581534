import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProfileActions from "../store/profilo.actions";
import * as fromApp from "../../../store/app.reducer";
import { MeData } from '../../../model/Me';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {
  @Input() me: MeData | null = null;

  public isEditing: boolean;
  public isEditingInstructor: boolean;
  public pendingValue: string;
  public value!: string;
  public valueChangeEvents: EventEmitter<string>;

  constructor(private store: Store<fromApp.AppState>) {
    this.isEditing = false;
    this.pendingValue = "";
    this.valueChangeEvents = new EventEmitter();
  }

  // I cancel the editing of the value.
  public cancel(id: string) : void {
    switch (id) {
      case 'instructor':
        this.isEditingInstructor = false;
        break;
      case 'cv' :
        this.isEditing = false;
        break;
    }
  }

  // I enable the editing of the value.
  public edit(id: string) : void {
    this.pendingValue = this.value;
    switch (id) {
      case 'instructor' :
        this.isEditingInstructor = true;
        break;
      case 'cv' :
        this.isEditing = true;
        break;
    }
  }

  private storeSub: Subscription;
  public meStore: MeData | null = null;
  public loading: boolean = false;

  ngOnInit(): void {
    this.storeSub = this.store.select('me').subscribe((me) => {
      this.meStore = me.me;
      this.loading = me.loading;
    });
  }

}
