<div class="row table-row">
  <div class="col">
    <div class="img-profile-container medium big-border mx-0">
      <img
        class="img-profile pointer"
        [src]="avatarUrl"
        alt="{{ patient.name }}"
        (click)="goToPazienteProfile(patient.id)"
      />
    </div>
  </div>
  <div class="col">
    {{
      patient.firstName.length > 17
        ? (patient.firstName | slice : 0 : 17) + ".."
        : patient.firstName
    }}
  </div>
  <div class="col">
    {{
      patient.lastName.length > 17
        ? (patient.lastName | slice : 0 : 17) + ".."
        : patient.lastName
    }}
  </div>
  <div class="col">
    {{ patient.crmNo }}
  </div>

  <div class="col last">
    <button
      type="button"
      class="do3-secondary-btn lightBlue text-uppercase w-auto me-2"
      (click)="handleDetailClick(patient)"
    >
      {{ "Dettagli" }}
    </button>
  </div>
</div>
