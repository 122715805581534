import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import * as fromApp from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import * as RichiesteActions from '../../features/richieste/store/richieste.actions';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-requests-container',
  templateUrl: './requests-container.component.html',
  styleUrls: ['./requests-container.component.scss'],
})
export class RequestsContainerComponent implements OnInit, OnDestroy {
  @Input() requestType: string = 'closed';
  public requests: Richiesta | null = null;
  public page: number = 0;
  public isLoading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = '';
  private storeSubscription: Subscription;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit(): void {
    if (this.requestType === 'closed') {
      console.log('request type closed');

      this.store.dispatch(
        RichiesteActions.fetchClosedRequests({
          searchRequest: true,
          statusValue: 'CLOSED',
          fromDate: this.getHumanDate(new Date(), true),
          toDate: this.getHumanDate(new Date(), false, true),
        })
      );
    } else if (this.requestType === 'open') {
      console.log('request type open', this.requestType);
      this.store.dispatch(
        RichiesteActions.fetchPastOpenRequests({
          searchRequest: true,
          statusValue: 'OPEN',
          fromDate: this.getHumanDate(new Date(), true),
          toDate: this.getHumanDate(new Date(), false, true),
        })
      );
    }

    this.storeSubscription = this.store
      .select('richieste')
      .subscribe((richieste) => {
        if (this.requestType === 'closed') {
          this.requests = richieste.pastClosedRichieste;
        } else if (this.requestType === 'open') {
          this.requests = richieste.pastOpenRichieste;
        }
        this.isLoading = richieste.loading;
        this.error = richieste.error;
        this.errorMessage = richieste.errorMessage;
      });
  }

  ngOnDestroy(): void {
    this.storeSubscription && this.storeSubscription.unsubscribe();
  }

  getHumanDate = (date: any, lastYear = false, yesterday = false) => {
    let dd: any = new Date();
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    if (yesterday && dd.getDate() === 1) {
      dd.setDate(dd.getDate() - 1);
      mm = String(dd.getMonth() + 1).padStart(2, '0');
      dd = String(dd.getDate()).padStart(2, '0');
    } else if (yesterday) {
      dd.setDate(dd.getDate() - 1);
      dd = String(dd.getDate()).padStart(2, '0');
    } else {
      dd.setDate(dd.getDate());
      dd = String(dd.getDate()).padStart(2, '0');
    }
    const yyyy = lastYear ? date.getFullYear() - 1 : date.getFullYear();
    const humanDate = yyyy + '-' + mm + '-' + dd;
    return humanDate;
  };

  changePage(page: any) {
    this.page = page;
    console.log('page future', this.page);
    if (this.requestType === 'closed') {
      this.store.dispatch(
        RichiesteActions.fetchClosedRequests({
          searchRequest: true,
          statusValue: 'CLOSED',
          fromDate: this.getHumanDate(new Date(), true),
          toDate: this.getHumanDate(new Date(), false, true),
          page: this.page,
        })
      );
    } else if (this.requestType === 'open') {
      this.store.dispatch(
        RichiesteActions.fetchPastOpenRequests({
          searchRequest: true,
          statusValue: 'OPEN',
          fromDate: this.getHumanDate(new Date(), true),
          toDate: this.getHumanDate(new Date(), false, true),
          page: this.page,
        })
      );
    }
  }

  handleDetailClick(richiesta: RichiestaDatum) {
    console.log('clicked richiesta', richiesta);
    this.router.navigate(['/detail', richiesta.id]);
  }
}
