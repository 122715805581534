import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistenza',
  templateUrl: './assistenza.component.html',
  styleUrls: ['./assistenza.component.scss']
})
export class AssistenzaComponent implements OnInit {
  headerTitle: string;
  headerDescription: string;

  accordions: any = [
    {
      funzioni_disponibili: [
        {
          title: "Posso caricare una foto o un’immagine diagnostica?",
          description: "Sì. Se vuoi caricare un’immagine diagnostica o un documento clinico come un referto, una radiografia o una ricetta vai alla sezione “Documenti”, clicca su “carica file” e attendi il caricamento."
        },
        {
          title: "Posso disattivare un questionario?",
          description: "Certo! Per disattivare un questionario vai alla sezione “questionari” presente nel tuo profilo e clicca sul tasto “no”."
        },
        {
          title: "Posso modificare le mie impostazioni di privacy?",
          description: "Sì, ti basta andare alla sezione “Privacy”, in quest’area potrai modificare le tue preferenze per il trattamento dei dati personali in qualunque momento."
        }
      ],
      televisita: [
        {
          title: "Come faccio ad accedere al Videoconsulto?",
          description: "Per accedere al videoconsulto vai alla sezione “Appuntamenti” e clicca sul tasto “Televisita”."
        },
        {
          title: "Il paziente non mi vede. Perché?",
          description: "Sei sicuro che la tua webcam sia accesa? Per verificare clicca sul tasto “video” in basso a sinistra."
        },
        {
          title: "Il medico non mi sente. Perché?  ",
          description: "Sei sicuro che il tuo microfono sia abilitato? Per verificare clicca sul tasto “microfono” in fondo alla pagina."
        }
      ]/*,
      diario_medico: [
        {
          title: "Come faccio a caricare un documento medico?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          title: "Come faccio a scaricare un documento medico?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          title: "Come faccio a scrivere ad un medico?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          title: "Come faccio a consultare la diagnosi?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
      ],
      agenda: [
        {
          title: "Dove posso trovare le televisite in programma?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
      ]*/
    }
  ]

  constructor() { }

  viewMode: any;
  ngOnInit(): void {
    this.viewMode = "funzioni_disponibili";
    this.headerTitle = "Funzioni Disponibili"
    this.headerDescription = "La navigazione in piattaforma ti crea qualche disagio? Niente paura. Consulta la nostra sezione dedicata per un supporto veloce.";
  }
}
