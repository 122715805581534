<ng-container *ngIf="allRichieste && !isLoading">
  <div class="anamnesi" *ngIf="allRichieste.length > 0">
    <div class="row do3-fw800 do3-blue-text header">
      <div class="col-3 pl-4" *ngIf="isFull">Dottore</div>
      <div class="col-3" *ngIf="isFull">Tipologia</div>
      <div class="col-3" *ngIf="isFull">ID</div>
    </div>
    <ng-container *ngFor="let richiesta of allRichieste; let i = index">
      <app-single-anamnesi
        *ngIf="richiesta.hasTriage"
        [richiesta]="richiesta"
        [isTriage]="false"
        [isFull]="isFull"
      ></app-single-anamnesi>
    </ng-container>
  </div>
  <ng-container *ngIf="allRichieste.length == 0">
    <h1 class="d-flex justify-content-center m-5 do3-fw600">
      Il paziente non ha ancora compilato i questionari MyHealth
    </h1>
  </ng-container>
</ng-container>
