import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-single-anamnesi',
  templateUrl: './single-anamnesi.component.html',
  styleUrls: ['./single-anamnesi.component.scss'],
})
export class SingleAnamnesiComponent implements OnInit {
  @Input() richiesta: any | null;
  @Input() isTriage: boolean = true;
  @Input() isFull: boolean = true;
  public anamnesi: any;
  public isLoading: boolean = true;
  public openPanel: boolean = false;

  constructor(private http: HttpClient) {}
  ngOnInit(): void {}

  getAnamnesi(id: string) {
    this.openPanel = !this.openPanel;
    return this.http
      .get(`${environment.NEW_API}/requests/${id}/triage`)
      .subscribe((data: any) => {
        console.log(data.data);
        this.isLoading = false;
        return (this.anamnesi = data.data);
      });
  }

  handleType(id: string | undefined) {
    switch (id) {
      case 'KPI_Q1':
        return 'health';
      case 'KPI_Q2':
        return 'nutrition';
      case 'KPI_Q3':
        return 'mind';
      case 'KPI_Q4':
        return 'movement';
      default:
        return '';
    }
  }
}
