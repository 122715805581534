<div class="container main-container profilo">
  <div class="row">
    <div class="col-12">
      <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>
    </div>
  </div>

  <div class="row">
    <div class="col fixed">
      <ul class="list-profilo">
        <li
          [ngClass]="{ active: activeTab == 'info' }"
          (click)="currentTab('infoTab')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.981"
            height="23"
            viewBox="0 0 22.981 23"
          >
            <path
              id="Tracciato_492"
              data-name="Tracciato 492"
              d="M13.509,2A11.512,11.512,0,0,0,5.048,21.294h0a11.512,11.512,0,0,0,16.923,0h0A11.512,11.512,0,0,0,13.509,2Zm0,20.722a9.21,9.21,0,0,1-6.389-2.59,6.907,6.907,0,0,1,12.778,0A9.21,9.21,0,0,1,13.509,22.722Zm-2.3-11.512a2.3,2.3,0,1,1,2.3,2.3A2.3,2.3,0,0,1,11.207,11.21Zm10.257,6.907a9.21,9.21,0,0,0-4.5-3.891,4.6,4.6,0,1,0-6.907,0,9.21,9.21,0,0,0-4.5,3.891,9.117,9.117,0,0,1-1.255-4.6,9.21,9.21,0,1,1,18.419,0A9.118,9.118,0,0,1,21.464,18.117Z"
              transform="translate(-2.019 -2)"
              fill="#2F2F2F"
            />
          </svg>
          Profilo
        </li>

        <li
          [ngClass]="{ active: activeTab == 'curriculum' }"
          (click)="currentTab('curriculumTab')"
        >
          <mat-icon class="material-symbols-outlined">description</mat-icon>
          Curriculum
        </li>

        <!--<li [ngClass]="{active: activeTab == 'cronologia'}" (click)="currentTab('cronologiaTab')">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.94" height="23.501" viewBox="0 0 23.94 23.501">
            <g id="Raggruppa_1376" data-name="Raggruppa 1376" transform="translate(0.25 -0.35)">
              <path id="Tracciato_1791" data-name="Tracciato 1791"
                d="M22.019,19.74H1.421A1.422,1.422,0,0,1,0,18.319V2.021A1.422,1.422,0,0,1,1.421.6h20.6A1.422,1.422,0,0,1,23.44,2.021v16.3A1.422,1.422,0,0,1,22.019,19.74ZM1.421,1.311a.711.711,0,0,0-.711.711v16.3a.711.711,0,0,0,.711.711h20.6a.711.711,0,0,0,.711-.711V2.021a.711.711,0,0,0-.711-.711Z"
                fill="#2F2F2F" stroke="#2F2F2F" stroke-width="0.5" />
              <path id="Tracciato_1792" data-name="Tracciato 1792"
                d="M23.183,20.169H5.771A1.422,1.422,0,0,1,4.35,18.748V6.391A1.422,1.422,0,0,1,5.771,4.97H23.183A1.422,1.422,0,0,1,24.6,6.391V18.748A1.422,1.422,0,0,1,23.183,20.169ZM5.771,5.681a.711.711,0,0,0-.711.711V18.748a.711.711,0,0,0,.711.711H23.183a.711.711,0,0,0,.711-.711V6.391a.711.711,0,0,0-.711-.711Zm6.651,18.792a.358.358,0,0,1-.322-.2l-.941-1.963A.355.355,0,1,1,11.8,22l.941,1.963a.353.353,0,0,1-.168.472A.346.346,0,0,1,12.422,24.473Zm4.109,0a.339.339,0,0,1-.154-.037.356.356,0,0,1-.168-.472L17.15,22a.355.355,0,1,1,.641.3l-.941,1.963A.346.346,0,0,1,16.532,24.473Z"
                transform="translate(-2.757 -2.769)" fill="#2F2F2F" stroke="#2F2F2F" stroke-width="0.5" />
              <path id="Tracciato_1793" data-name="Tracciato 1793"
                d="M28.119,58.894H19.175a.353.353,0,0,1-.355-.355,2.248,2.248,0,0,1,2.245-2.249h5.171a2.248,2.248,0,0,1,2.245,2.245A.37.37,0,0,1,28.119,58.894Zm-8.552-.711h8.156A1.534,1.534,0,0,0,26.233,57H21.061A1.539,1.539,0,0,0,19.567,58.184Z"
                transform="translate(-11.927 -35.293)" fill="#2F2F2F" stroke="#2F2F2F" stroke-width="0.5" />
              <g id="Raggruppa_1369" data-name="Raggruppa 1369" transform="translate(9.936 17.858)">
                <path id="Tracciato_1794" data-name="Tracciato 1794"
                  d="M30.342,48.431H27.485a.355.355,0,0,1,0-.711h2.853a.355.355,0,0,1,0,.711Z"
                  transform="translate(-27.13 -47.72)" fill="#2F2F2F" stroke="#2F2F2F" stroke-width="0.5" />
              </g>
              <g id="Raggruppa_1370" data-name="Raggruppa 1370" transform="translate(6.673 4.186)">
                <path id="Tracciato_1795" data-name="Tracciato 1795"
                  d="M27.959,21.616H18.575a.353.353,0,0,1-.355-.355,5.047,5.047,0,0,1,10.094,0A.353.353,0,0,1,27.959,21.616Zm-9.013-.711h8.644a4.336,4.336,0,0,0-8.644,0Zm4.322-5.578a2.469,2.469,0,1,1,2.469-2.469A2.472,2.472,0,0,1,23.267,15.327Zm0-4.227a1.758,1.758,0,1,0,1.758,1.758A1.761,1.761,0,0,0,23.267,11.1Z"
                  transform="translate(-18.22 -10.39)" fill="#2F2F2F" stroke="#2F2F2F" stroke-width="0.5" />
              </g>
            </g>
          </svg>
          Cronologia
        </li>

        <li [ngClass]="{active: activeTab == 'documenti'}" (click)="currentTab('documentiTab')">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.217" height="23.896" viewBox="0 0 19.217 23.896">
            <path id="Tracciato_1796" data-name="Tracciato 1796"
              d="M9.849,11.359h1.17a1.17,1.17,0,1,0,0-2.34H9.849a1.17,1.17,0,1,0,0,2.34Zm0,2.34a1.17,1.17,0,1,0,0,2.34h7.019a1.17,1.17,0,1,0,0-2.34Zm12.868-3.58a1.532,1.532,0,0,0-.07-.316V9.7a1.252,1.252,0,0,0-.222-.328h0L15.406,2.351h0a1.252,1.252,0,0,0-.328-.222.374.374,0,0,0-.105,0A1.029,1.029,0,0,0,14.587,2H7.509A3.509,3.509,0,0,0,4,5.509V21.887A3.509,3.509,0,0,0,7.509,25.4h11.7a3.509,3.509,0,0,0,3.509-3.509V10.119ZM15.7,5.989l3.03,3.03h-1.86a1.17,1.17,0,0,1-1.17-1.17Zm4.679,15.9a1.17,1.17,0,0,1-1.17,1.17H7.509a1.17,1.17,0,0,1-1.17-1.17V5.509a1.17,1.17,0,0,1,1.17-1.17h5.849V7.849a3.509,3.509,0,0,0,3.509,3.509h3.509Zm-3.509-3.509H9.849a1.17,1.17,0,0,0,0,2.34h7.019a1.17,1.17,0,0,0,0-2.34Z"
              transform="translate(-3.75 -1.75)" fill="#2F2F2F" stroke="#fff" stroke-width="0.5" />
          </svg>
          Documenti
        </li>-->

        <li
          [ngClass]="{ active: activeTab == 'anamnesi' }"
          (click)="currentTab('anamnesiTab')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.029"
            height="27.029"
            viewBox="0 0 27.029 27.029"
          >
            <g
              id="Raggruppa_1465"
              data-name="Raggruppa 1465"
              transform="translate(-52 0)"
            >
              <path
                id="Tracciato_1815"
                data-name="Tracciato 1815"
                d="M76.07,26.437l-1.463.845a.845.845,0,0,1-1.264-.813l.7-7.232a.844.844,0,0,1,1.19-.688l6.614,3.009a.845.845,0,0,1,.073,1.5l-1.463.845L81.726,26.1a2.534,2.534,0,0,1-4.389,2.534Z"
                transform="translate(-3.315 -2.87)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1816"
                data-name="Tracciato 1816"
                d="M.845,1.689H2.534A.845.845,0,1,0,2.534,0H.845a.845.845,0,1,0,0,1.689Z"
                transform="translate(66.028 18.673) rotate(-30)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1817"
                data-name="Tracciato 1817"
                d="M.845,1.689H2.534A.845.845,0,1,0,2.534,0H.845a.845.845,0,1,0,0,1.689Z"
                transform="translate(73.343 14.45) rotate(-30)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1818"
                data-name="Tracciato 1818"
                d="M.845,1.689H2.534A.845.845,0,1,0,2.534,0H.845a.845.845,0,1,0,0,1.689Z"
                transform="translate(69.572 14.675) rotate(-120)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1819"
                data-name="Tracciato 1819"
                d="M53.689,7.745V2.677a.845.845,0,0,1,.845-.845v0a.845.845,0,0,1,.845.845V7.745a.845.845,0,1,0,1.689,0V2.677A2.534,2.534,0,0,0,54.534.143v0A2.534,2.534,0,0,0,52,2.677V7.745a.845.845,0,1,0,1.689,0Z"
                transform="translate(0 -0.143)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1820"
                data-name="Tracciato 1820"
                d="M53.689,6.757V.845A.845.845,0,1,0,52,.845V6.757a.845.845,0,1,0,1.689,0Z"
                transform="translate(0 10.136)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1821"
                data-name="Tracciato 1821"
                d="M52.845,5.689H55.8A.845.845,0,1,0,55.8,4H52.845a.845.845,0,1,0,0,1.689Z"
                transform="translate(0 -0.199)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1822"
                data-name="Tracciato 1822"
                d="M56.223,13.534h.845a2.534,2.534,0,0,1-2.534,2.534H52.845A.845.845,0,0,1,52,15.223V11.845A.845.845,0,0,1,52.845,11h1.689a2.534,2.534,0,0,1,2.534,2.534Zm-.845,0a.845.845,0,0,0-.845-.845h-.845v1.689h.845a.845.845,0,0,0,.845-.845h0Z"
                transform="translate(0 -0.864)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1823"
                data-name="Tracciato 1823"
                d="M56.223,13.534h.845a2.534,2.534,0,0,1-2.534,2.534H52.845A.845.845,0,0,1,52,15.223V11.845A.845.845,0,0,1,52.845,11h1.689a2.534,2.534,0,0,1,2.534,2.534Zm-.845,0a.845.845,0,0,0-.845-.845h-.845v1.689h.845a.845.845,0,0,0,.845-.845h0Z"
                transform="translate(0 2.514)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1824"
                data-name="Tracciato 1824"
                d="M26.011,3.134A2.534,2.534,0,0,0,23.477.6H20.943a2.534,2.534,0,0,0-2.534,2.534h0a2.534,2.534,0,0,0,2.534,2.534h2.534a2.534,2.534,0,0,0,2.534-2.534h0Z"
                transform="translate(40.347 11.225)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
              <path
                id="Tracciato_1825"
                data-name="Tracciato 1825"
                d="M79.427,4.534h.845A2.535,2.535,0,0,0,77.738,2h-15.2A2.535,2.535,0,0,0,60,4.534h0a2.535,2.535,0,0,0,2.534,2.534h15.2a2.535,2.535,0,0,0,2.534-2.534Z"
                transform="translate(-1.243 -0.311)"
                fill="#2F2F2F"
                fill-rule="evenodd"
              />
            </g>
          </svg>
          Questionario
        </li>

        <!-- <li
          [ngClass]="{ active: activeTab == 'fatturazione' }"
          (click)="currentTab('fatturazioneTab')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="16.429"
            viewBox="0 0 23 16.429"
          >
            <g
              id="Raggruppa_1378"
              data-name="Raggruppa 1378"
              transform="translate(-2 -6)"
            >
              <path
                id="Tracciato_1797"
                data-name="Tracciato 1797"
                d="M22.536,6H4.464A2.464,2.464,0,0,0,2,8.464v11.5a2.464,2.464,0,0,0,2.464,2.464H22.536A2.464,2.464,0,0,0,25,19.964V8.464A2.464,2.464,0,0,0,22.536,6ZM4.464,7.643H22.536a.821.821,0,0,1,.821.821v1.643H3.643V8.464A.821.821,0,0,1,4.464,7.643ZM22.536,20.786H4.464a.821.821,0,0,1-.821-.821V13.393H23.357v6.571A.821.821,0,0,1,22.536,20.786Z"
                transform="translate(0)"
                fill="#2F2F2F"
              />
              <rect
                id="Rettangolo_440"
                data-name="Rettangolo 440"
                width="7"
                height="2"
                transform="translate(14 18.429)"
                fill="#2F2F2F"
              />
            </g>
          </svg>
          Pagamenti
        </li> -->

        <li
          [ngClass]="{ active: activeTab == 'privacy' }"
          (click)="currentTab('privacyTab')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.197"
            height="27.425"
            viewBox="0 0 19.197 27.425"
          >
            <g
              id="Raggruppa_1486"
              data-name="Raggruppa 1486"
              transform="translate(-5 -2)"
            >
              <path
                id="Tracciato_1832"
                data-name="Tracciato 1832"
                d="M22.826,10.227H21.455V8.856a6.856,6.856,0,1,0-13.712,0v1.371H6.371A1.371,1.371,0,0,0,5,11.6V22.245a4.093,4.093,0,0,0,2.273,3.68l6.712,3.355a1.371,1.371,0,0,0,1.227,0l6.712-3.355a4.093,4.093,0,0,0,2.272-3.68V11.6A1.371,1.371,0,0,0,22.826,10.227ZM10.485,8.856a4.114,4.114,0,1,1,8.227,0v1.371H10.485Zm10.97,13.389a1.363,1.363,0,0,1-.758,1.227L14.6,26.52,8.5,23.47a1.363,1.363,0,0,1-.758-1.226V12.97H21.455Z"
                transform="translate(0 0)"
                fill="#2F2F2F"
              />
              <path
                id="Tracciato_1833"
                data-name="Tracciato 1833"
                d="M12.371,12A1.371,1.371,0,0,0,11,13.371v2.742a1.371,1.371,0,0,0,2.742,0V13.371A1.371,1.371,0,0,0,12.371,12Z"
                transform="translate(2.227 3.712)"
                fill="#2F2F2F"
              />
            </g>
          </svg>
          Privacy
        </li>
        <!-- <li
          [ngClass]="{ active: activeTab == 'privacy' }"
          (click)="currentTab('privacyTab')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.197"
            height="27.425"
            viewBox="0 0 19.197 27.425"
          >
            <g
              id="Raggruppa_1486"
              data-name="Raggruppa 1486"
              transform="translate(-5 -2)"
            >
              <path
                id="Tracciato_1832"
                data-name="Tracciato 1832"
                d="M22.826,10.227H21.455V8.856a6.856,6.856,0,1,0-13.712,0v1.371H6.371A1.371,1.371,0,0,0,5,11.6V22.245a4.093,4.093,0,0,0,2.273,3.68l6.712,3.355a1.371,1.371,0,0,0,1.227,0l6.712-3.355a4.093,4.093,0,0,0,2.272-3.68V11.6A1.371,1.371,0,0,0,22.826,10.227ZM10.485,8.856a4.114,4.114,0,1,1,8.227,0v1.371H10.485Zm10.97,13.389a1.363,1.363,0,0,1-.758,1.227L14.6,26.52,8.5,23.47a1.363,1.363,0,0,1-.758-1.226V12.97H21.455Z"
                transform="translate(0 0)"
                fill="#2F2F2F"
              />
              <path
                id="Tracciato_1833"
                data-name="Tracciato 1833"
                d="M12.371,12A1.371,1.371,0,0,0,11,13.371v2.742a1.371,1.371,0,0,0,2.742,0V13.371A1.371,1.371,0,0,0,12.371,12Z"
                transform="translate(2.227 3.712)"
                fill="#2F2F2F"
              />
            </g>
          </svg>
          Privacy
        </li> -->
      </ul>
    </div>

    <div class="col text-section">
      <ng-container [ngSwitch]="currentStep$ | async">
        <app-info-profilo
          [me]="this.me"
          *ngSwitchCase="'info'"
        ></app-info-profilo>
        <app-curriculum
          [me]="this.me"
          *ngSwitchCase="'curriculum'"
        ></app-curriculum>
        <app-cronologia *ngSwitchCase="'cronologia'"></app-cronologia>
        <app-documenti *ngSwitchCase="'documenti'"></app-documenti>
        <app-fatturazione *ngSwitchCase="'fatturazione'"></app-fatturazione>
        <app-anamnesi *ngSwitchCase="'anamnesi'"></app-anamnesi>
        <app-privacy *ngSwitchCase="'privacy'"></app-privacy>
      </ng-container>
    </div>
  </div>
</div>
