<button (click)="onClick()" class="btn mt-4 mb-5 d-flex">
  <div class="plus">+</div>
  <div>CARICA</div>
</button>
<div></div>
<input
  type="file"
  #fileUpload
  id="fileUpload"
  name="fileUpload"
  multiple="multiple"
  accept="image/*, application/pdf"
  style="display: none"
/>
