import { createReducer, on } from '@ngrx/store';
import { MedicData } from 'src/app/model/MedicProfile';
import * as medicProfileActions from './medic-profile.actions';

export interface State {
  medic: MedicData | null;
  loading: boolean;
  error: boolean;
  errorMessage: any | null;
}

const initialState: State = {
  medic: null,
  loading: false,
  error: false,
  errorMessage: null,
};

const _medicProfileReducer = createReducer(
  initialState,

  on(medicProfileActions.fetchMedicProfile, (state) => ({
    ...state,
    loading: true,
  })),

  on(medicProfileActions.fetchMedicProfileSuccess, (state, action) => ({
    ...state,
    medic: action.medic,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(medicProfileActions.fetchMedicProfileFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  }))
);

export function medicProfileReducer(state: State | undefined, action: any) {
  return _medicProfileReducer(state, action);
}
