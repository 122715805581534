import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as MeActions from '../../../core/header/store/me.actions';
import { MeData } from 'src/app/model/Me';
import * as fromApp from '../../../store/app.reducer';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-dati-profilo',
  templateUrl: './dati-profilo.component.html',
  styleUrls: ['./dati-profilo.component.scss'],
})
export class DatiProfiloComponent implements OnInit, OnDestroy {
  storeSub!: Subscription;
  me!: MeData | null;
  isLoading: boolean = true;
  @Input() fetchMe: boolean = true;
  public meStore: MeData | null = null;
  public loading: boolean = false;

  constructor(private store: Store<fromApp.AppState>) {}

  onSaveProfile(form: NgForm) {
    console.log('form: ', form.value);
    this.store.dispatch(MeActions.updateMe({ me: form.value }));
  }

  ngOnInit(): void {
    this.store.dispatch(MeActions.fetchMe());
    this.storeSub = this.store.select('me').subscribe((me) => {
      this.me = me.me;
      this.loading = me.loading;
    });
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
