import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MedicData } from 'src/app/model/MedicProfile';
import * as fromApp from 'src/app/store/app.reducer';
import { environment } from 'src/environments/environment';
import * as MedicProfileActions from './store/medic-profile.actions';

interface Detail {
  name: string;
  specialization: string;
  cv: string;
  education: string[];
  publications: string[];
  languages: string[];
  address: Address[];
  illness: string[];
}

interface Address {
  name: string;
  street: string;
}

@Component({
  selector: 'app-medic-profile',
  templateUrl: './medic-profile.component.html',
  styleUrls: ['./medic-profile.component.scss'],
})
export class MedicProfileComponent implements OnInit, OnDestroy {
  public doctor: Detail = {
    name: 'Dott. Mario Rossi',
    specialization: 'Urologia',
    cv: 'Laureato in Medicina e Chirurgia nel 1998 con il massimo dei voti. Assunto all’Istituto Europeo di Oncologia dal 2003, dal 2005 ha iniziato ad occuparsi di chirurgia robotica effettuando stage di formazione in Italia e all’estero (Bordeaux). Ha portato l’attività robotica presso lo IEO che ha acquisito il sistema da Vinci nel novembre 2006. Dal 2008 al 2009, dopo il conseguimento dell’abilitazione chirurgica per lo stato della Florida (USA) per meriti, ha collaborato con il maggior esperto mondiale di chirurgia robotica del cancro prostatico con il quale ha eseguito numerosi interventi chirurgici. Dal febbraio 2011 è Dirigente Medico di I Livello presso l’Unità Operativa di Urologia della Fondazione IRCCS Ca’ Granda Ospedale Maggiore Policlinico; è stato inoltre Direttore della scuola di Chirurgia Robotica dell’Istituto Europeo di Oncologia di Milano e Professore a contratto della Scuola di Specializzazione in Urologia dell’Università degli Studi di Milano, per conto della quale ha svolto un’intensa attività accademica.',
    education: ['Università degli Studi di Milano'],
    publications: ['Libri'],
    languages: ['Inglese', 'Italiano', 'Spagnolo'],
    address: [
      {
        name: 'Poliambulatorio Centro Medico Srl',
        street: 'Via Monte Sabotino 39 Modena',
      },
      {
        name: 'Centro Medico Visconti',
        street: 'Via Visconti di Modrone 8 Milano',
      },
    ],
    illness: ['Calcolosi', 'Cistite', 'Ipertensione'],
  };
  public loggedIn: boolean = false;
  private storeSubscription: Subscription;
  private medicId: string = '';
  private paramSubscription: any;
  public doctorFromStore: MedicData | null;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private location: Location
  ) {}

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe((params) => {
      const idParam = params.get('id');
      if (idParam) {
        this.medicId = idParam;
        console.log('Medic id from router:', this.medicId);
        this.store.dispatch(
          MedicProfileActions.fetchMedicProfile({ medicId: this.medicId })
        );
      } else {
        this.router.navigate(['/appuntamenti']);
      }
    });

    this.storeSubscription = this.store
      .select('medicProfile')
      .subscribe((data) => {
        this.loading = data.loading;
        this.doctorFromStore = data.medic;
        console.log('Medic profile from store:', this.doctorFromStore);
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  getDoctorAvatar() {
    return `${environment.NEW_API}/doctors/${this.doctorFromStore?.id}/image`;
  }

  goBack() {
    this.location.back();
  }
}
