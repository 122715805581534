import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MeData } from 'src/app/model/Me';
import * as fromApp from '../../store/app.reducer';
import * as ConsensoActions from './store/consenso.actions';

@Component({
  selector: 'app-consenso',
  templateUrl: './consenso.component.html',
  styleUrls: ['./consenso.component.scss'],
})
export class ConsensoComponent implements OnInit, OnDestroy {
  private meSubscription!: Subscription;
  private storeSubscription: Subscription;
  public me!: MeData | null;
  public isContentLoading: boolean = false;
  public isOTPLoading: boolean = false;
  public consentFromStore: any;
  private paramSubscription: any;
  private richiestaId: string;
  public showOTP: boolean = false;
  public otpCode: string = '';
  public error: boolean = false;
  public errorTitle: string;
  public errorMessage: string;

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paramSubscription = this.route.paramMap.subscribe((params) => {
      const idParam = params.get('id');
      if (idParam) {
        this.richiestaId = idParam;
        console.log('Richiesta id from router:', this.richiestaId);
      } else {
        this.router.navigate(['/appuntamenti']);
      }
    });

    this.store.dispatch(ConsensoActions.fetchConsent({ id: this.richiestaId }));
    this.storeSubscription = this.store
      .select('consent')
      .subscribe((consent) => {
        this.consentFromStore = consent;
        this.isContentLoading = consent.contentLoading;
        this.error = consent.error;
        if (consent.error) {
          switch (consent.errorMessage) {
            case 'otp/invalid-otp':
              this.errorTitle = 'Codice OTP non valido';
              this.errorMessage =
                'Inserisci il codice di autenticazione ricevuto nella mail.';
              break;
            default:
              console.error(
                'ConsensoComponent: errorMessage',
                consent.errorMessage
              );
              this.errorTitle = 'Errore';
              this.errorMessage =
                'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.';
              break;
          }
        }
        console.log('consent from component', this.consentFromStore);
      });
  }

  ngOnDestroy(): void {
    if (this.meSubscription) {
      this.meSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  toggleConsenso(e: any) {
    console.log('showOTP', this.showOTP);
    // TODO: check not working, this.showOTP first time is false
    if (this.showOTP) {
      this.requestOTP();
    }
    console.log('toggle consenso', this.showOTP);
  }

  requestOTP() {
    this.isOTPLoading = true;
    this.store.dispatch(ConsensoActions.fetchOTP({ id: this.richiestaId }));
    console.log('requestOTP');
  }

  acceptInformedConsent() {
    this.store.dispatch(
      ConsensoActions.acceptConsent({
        id: this.richiestaId,
        accepted: this.showOTP,
        otp: this.otpCode,
      })
    );
  }
}
