// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'drfeeldev',
    apiKey: 'AIzaSyArRAWAc3oOjlCR1UNms94QU-PYHj31Ui8',
    authDomain: 'drfeeldev.firebaseapp.com',
    storageBucket: 'drfeeldev.appspot.com',
    messagingSenderId: '329653049237',
    appId: '1:329653049237:web:0013d1bf4f434c81d8c89c',
    measurementId: 'G-8MRT1JQGZ7',
  },
  production: false,
  NEW_API: 'https://api-dev.drfeel.com/doctor',

  streamChat: {
    // DrFeel dev
    userId: 'snowy-night-4',
    userToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoic25vd3ktbmlnaHQtNCJ9.s9e6aPCs_LG4qlbTgCz6gd4XqA2kpnal4WZr99qd0qw',
    // apiKey: '7km82tt4bcsy',
    // Caffeina assunta dev
    // apiKey: '9vsamt2wr2u9',
    // Caffeina TestChatDF
    // apiKey: 'fxv8phrfs54a',
    //  DevDrfeel.com
    apiKey: 'kcuuj4qsuz4h',
  },

  path_url_base: 'https://doctordev.drfeel.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
