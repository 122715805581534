import { createAction, props } from '@ngrx/store';
import { MedicData } from 'src/app/model/MedicProfile';

export const fetchMedicProfile = createAction(
  '[MedicProfile] Fetch MedicProfile',
  props<{ medicId: string }>()
);

export const fetchMedicProfileSuccess = createAction(
  '[MedicProfile] Fetch MedicProfile Success',
  props<{ medic: MedicData }>()
);

export const fetchMedicProfileFailure = createAction(
  '[MedicProfile] Fetch MedicProfile Failure',
  props<{ error: any }>()
);
