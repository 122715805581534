<div class="do3-gray-text f-size-smaller">
  <div class="container main-container mt-5">
    <div class="row do3-medic-top">
      <div class="col d-flex align-items-center ml-4">
        <button class="do3-back-btn col-12" (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
          Torna indietro
        </button>
      </div>
      <div class="col" *ngIf="loggedIn">
        <div class="container">
          <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>
        </div>
      </div>
    </div>

    <app-loading-spinner class="row d-flex justify-content-center" *ngIf="loading"></app-loading-spinner>

    <div class="row do3-medic-content" *ngIf="!loading && doctorFromStore">
      <div class="col-3 vh-100 left-side mt-5">
        <div class="container right-border">
          <div class="mt-4">
            <h1 class="m-0">Sede:</h1>
            <ul class="mb-3">
              <li class="do3-lightblue-text mb-3" *ngFor="let address of doctorFromStore.location">
                <p *ngIf="address.description">{{ address.description }}</p>
                <p class="do3-gray-text">{{ address.street }} - {{ address.city }}</p>
              </li>
            </ul>
            <h1 class="m-0">Patologie comuni trattate:</h1>
            <ul>
              <li class="mb-1" *ngFor="let patologia of doctorFromStore.Illness">
                <p>{{ patologia.name }}</p>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="col-9 right-side">
        <div class="w-85 right-container vh-100 mt-5">
          <div class="d-flex align-items-center mt-4 mb-4">
            <div class="img-profile-container large bolder-border">
              <img [src]="getDoctorAvatar()" class="img-profile" [alt]="doctorFromStore.name">
            </div>

            <div class="ml-4 pr-3">
              <h1 class="do3-blue-text fw-bold mb-0">Medico:</h1>
              <h3 class="do3-lightblue-text fw-bold mb-0">{{ doctorFromStore.name }}</h3>
            </div>
            <div class="ml-3 pr-3">
              <h1 class="do3-blue-text fw-bold mb-0" *ngIf="doctorFromStore.specializzazioni">
                {{ doctorFromStore.specializzazioni.length == 1 ? 'Specialistica:' : 'Specialistiche:' }} </h1>
              <h3 class="do3-lightblue-text fw-bold mb-0" *ngFor="let spec of doctorFromStore.specializzazioni">
                {{ spec.name }}
              </h3>
            </div>
          </div>
          <!-- Containers to remove/add -->
          <div class="overflow-auto doc-text">
            <div class="">
              <h1 class="mb-0">Curriculum</h1>
              <!-- <p [innerHTML]="doctorFromStore.cv">

              </p> -->
              <p>
                {{doctorFromStore.cv}}
              </p>
              <h1 class="mb-0">Istruzione e Formazione</h1>
              <ul>
                <li class="mb-0" *ngFor="let ed of doctorFromStore.education">
                  {{ed.period}} - {{ed.title}} - {{ed.description}}
                </li>
              </ul>
              <h1 class="mb-0">Pubblicazioni</h1>
              <ul>
                <li class="mb-0" *ngFor="let pub of doctorFromStore.publication">
                  {{pub.description}} {{pub.period}}
                </li>
              </ul>
              <h1 class="mb-0">Lingue parlate</h1>
              <ul>
                <li class="mb-0" *ngFor="let lang of doctorFromStore.languages">
                  {{lang}}
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
