import { Action, createReducer, on } from '@ngrx/store';
import * as ProfiloPazienteActions from './profilo-paziente.actions';
import { ProfiloPazienteDatum } from 'src/app/model/ProfiloPaziente';
import { MeasurementDatum } from 'src/app/model/PatientMeasurement';

export interface State {
  profiloPaziente: ProfiloPazienteDatum | null;
  pazienteMeasurements: MeasurementDatum | null;
  loading: boolean;
  error: boolean;
  errorMessage: any;
  documents: any[] | null;
  loadingDocs: boolean;
  richieste: any[] | null;
}

const initialState: State = {
  profiloPaziente: null,
  pazienteMeasurements: null,
  loading: false,
  error: false,
  errorMessage: null,
  documents: [],
  loadingDocs: false,
  richieste: null,
};

const _profiloPazienteReduces = createReducer(
  initialState,

  on(ProfiloPazienteActions.fetchProfiloPaziente, (state) => ({
    ...state,
    profiloPaziente: null,
    loading: true,
  })),

  on(ProfiloPazienteActions.fetchProfiloPazienteSuccess, (state, action) => ({
    ...state,
    profiloPaziente: action.profiloPaziente,
    loading: false,
  })),

  on(ProfiloPazienteActions.fetchProfiloPazienteFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(ProfiloPazienteActions.fetchProfiloMeasurementsPaziente, (state) => ({
    ...state,
    pazienteMeasurements: null,
    loading: true,
  })),

  on(
    ProfiloPazienteActions.fetchProfiloMeasurementsPazienteSuccess,
    (state, action) => ({
      ...state,
      pazienteMeasurements: action.measurements,
      loading: false,
    })
  ),

  on(
    ProfiloPazienteActions.fetchProfiloMeasurementsPazienteFailure,
    (state, action) => ({
      ...state,
      loading: false,
      error: true,
      errorMessage: action.error,
    })
  ),

  on(ProfiloPazienteActions.fetchDocumentsPaziente, (state) => ({
    ...state,
    loadingDocs: true,
  })),

  on(ProfiloPazienteActions.fetchDocumentsPazienteSuccess, (state, action) => ({
    ...state,
    documents: action.documents,
    loadingDocs: false,
  })),

  on(ProfiloPazienteActions.fetchDocumentsPazienteFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loadingDocs: false,
  })),

  on(ProfiloPazienteActions.resetProfiloPaziente, (state) => ({
    ...state,
    profiloPaziente: null,
    loading: false,
    error: false,
    loadingDocs: false,
    documents: null,
  })),

  on(ProfiloPazienteActions.fetchRichiestePazienteSuccess, (state, action) => ({
    ...state,
    richieste: action.richieste,
  })),

  on(ProfiloPazienteActions.fetchRichiestePaziente, (state) => ({
    ...state,
    richieste: null,
  }))
);

export function profiloPazienteReducer(
  state: State | undefined,
  action: Action
) {
  return _profiloPazienteReduces(state, action);
}
