import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kpi-input',
  templateUrl: './kpi-input.component.html',
  styleUrls: ['./kpi-input.component.scss'],
})
export class KpiInputComponent implements OnInit {
  @Input() patientId: string = '';
  @Input() isHm: boolean | null = false;
  myHealthScore: string;
  health: string;
  mind: string;
  movement: string;
  nutrition: string;
  public loading: boolean = true;
  public error: boolean = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getHealthScore();
  }

  getHealthScore() {
    this.loading = true;
    this.http
      .get(`${environment.NEW_API}/hm/patients/${this.patientId}/health-score`)
      .subscribe(
        (response: any) => {
          if (response.data) {
            this.myHealthScore = response.data.score || '';
            this.health = response.data.zoneScore.HEALTH || '';
            this.mind = response.data.zoneScore.MIND || '';
            this.movement = response.data.zoneScore.MOVEMENT || '';
            this.nutrition = response.data.zoneScore.NUTRITION || '';
          } else {
            this.myHealthScore = '';
            this.health = '';
            this.mind = '';
            this.movement = '';
            this.nutrition = '';
          }

          console.log('kpi input response', response);
          this.loading = false;
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }

  private errorHandling(errMessage: any) {
    console.error(errMessage);
    this.error = true;
    this.loading = false;
  }

  onSubmit() {
    if (!this.isHm) {
      return;
    }
    const data = {
      score: this.myHealthScore,
      zoneScore: {
        HEALTH: this.health,
        MIND: this.mind,
        NUTRITION: this.nutrition,
        MOVEMENT: this.movement,
      },
    };

    this.http
      .post(
        `${environment.NEW_API}/hm/patients/${this.patientId}/health-score`,
        data
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.getHealthScore();
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }
}
