import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-consenso-informato',
  templateUrl: './consenso-informato.component.html',
  styleUrls: ['./consenso-informato.component.scss'],
})
export class ConsensoInformatoComponent implements OnInit {
  @Input() doctor: string = '';

  constructor() {}

  ngOnInit(): void {}
}
