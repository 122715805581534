<div
  class="alert alert-danger alert-dismissible show d-flex align-items-center mt-5"
  role="alert"
  *ngIf="error"
>
  <svg
    class="bi flex-shrink-0 me-2"
    width="24"
    height="24"
    role="img"
    aria-label="Danger:"
  >
    <use xlink:href="#exclamation-triangle-fill" />
  </svg>
  <div>
    {{ errorMessage }}
  </div>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="alert"
    aria-label="Close"
  ></button>
</div>
<app-loading-spinner
  class="row d-flex justify-content-center"
  *ngIf="isLoading"
></app-loading-spinner>

<div class="row" *ngIf="!isLoading && requests">
  <div class="col">
    <app-appuntamenti-list
      [richieste]="requests"
      [mode]="'storico'"
      [listType]="requestType === 'open' ? 'open' : 'closed'"
      (onClick)="handleDetailClick($event)"
      [page]="page"
      (changePage)="changePage($event)"
    ></app-appuntamenti-list>
  </div>
</div>
