import { createAction, props } from '@ngrx/store';
import { ProfiloPazienteDatum } from 'src/app/model/ProfiloPaziente';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
import { RichiestaDatum } from 'src/app/model/Richiesta';

export const fetchProfiloPaziente = createAction(
  '[Profilo-Paziente] Fetch Paziente',
  props<{ pazienteId: string }>()
);

export const fetchProfiloPazienteSuccess = createAction(
  '[Profilo-Paziente] Fetch Paziente Success',
  props<{ profiloPaziente: ProfiloPazienteDatum }>()
);

export const fetchProfiloPazienteFailure = createAction(
  '[Profilo-Paziente] Fetch Paziente Success',
  props<{ error: any }>()
);

export const fetchProfiloMeasurementsPaziente = createAction(
  '[Profilo-Paziente] Fetch Measurements Paziente',
  props<{ pazienteId: string; isHm: boolean }>()
);

export const fetchProfiloMeasurementsPazienteSuccess = createAction(
  '[Profilo-Paziente] Fetch Measurements Paziente Success',
  props<{ measurements: any }>()
);

export const fetchProfiloMeasurementsPazienteFailure = createAction(
  '[Profilo-Paziente] Fetch Measurements Paziente Failure',
  props<{ error: any }>()
);

export const fetchDocumentsPaziente = createAction(
  '[Profilo Paziente] Fetch Documents',
  props<{ pazienteId: String; isHm?: boolean }>()
);

export const fetchDocumentsPazienteSuccess = createAction(
  '[Profilo Paziente] Fetch Documents Success',
  props<{ documents: ProfiloDocumentDatum[] }>()
);

export const fetchDocumentsPazienteFail = createAction(
  '[Profilo Paziente] Fetch Documents Fail',
  props<{ error: any }>()
);

export const fetchRichiestePaziente = createAction(
  '[Profilo Paziente] Fetch Richieste',
  props<{
    idPatient: string;
    withTriage: boolean;
    surveyRequest?: boolean;
    isHm?: boolean;
  }>()
);
export const fetchRichiestePazienteSuccess = createAction(
  '[Profilo Paziente] Fetch Richieste Success',
  props<{
    richieste: RichiestaDatum[];
  }>()
);

export const resetProfiloPaziente = createAction(
  '[Profilo-Paziente] Reset Paziente'
);
