import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ProfiloPazienteActions from '../../features/profilo-paziente/store/profilo-paziente.actions';
import * as fromApp from '../../store/app.reducer';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit {
  @Input() pazienteId: string;
  @Input() isHm: boolean = false;
  @Input() isLoading: boolean = true;
  @Input() isFull: boolean = true;
  private profiloPazienteSub: Subscription;
  public allRichieste: any[] | null;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(
      ProfiloPazienteActions.fetchRichiestePaziente({
        idPatient: this.pazienteId,
        surveyRequest: true,
        withTriage: false,
      })
    );
    this.profiloPazienteSub = this.store
      .select('profiloPaziente')
      .subscribe((state) => {
        this.allRichieste = state.richieste;
      });
  }

  ngOnDestroy() {
    if (this.profiloPazienteSub) {
      this.profiloPazienteSub.unsubscribe();
    }
  }
}
