import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as ConsensoActions from './consenso.actions';

@Injectable()
export class ConsensoEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}

  fetchConsent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsensoActions.fetchConsent),
      switchMap((action) => {
        console.log('fetching consent');
        return this.http.get<any>(
          environment.NEW_API + '/requests/' + action.id + '/informedConsent'
        );
      }),
      map((consent) => {
        console.log('consent from effects', JSON.stringify(consent));
        if (consent) {
          return ConsensoActions.fetchConsentSuccess({
            consent: consent.data,
          });
        } else {
          return ConsensoActions.fetchConsentFail({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while fetching consent:', error);
        return of(
          ConsensoActions.fetchConsentFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    );
  });

  requestOTP$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsensoActions.fetchOTP),
      switchMap((action) => {
        return this.http.get<any>(
          environment.NEW_API +
            '/requests/' +
            action.id +
            '/informedConsent/otp',
          {}
        );
      }),
      map((otp) => {
        console.log('otp request success from effects', JSON.stringify(otp));
        if (otp) {
          return ConsensoActions.fetchOTPSuccess({
            otp: otp.data,
          });
        } else {
          return ConsensoActions.fetchOTPFail({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while requesting OTP:', error);
        return of(
          ConsensoActions.fetchOTPFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    );
  });

  acceptConsent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsensoActions.acceptConsent),
      switchMap((action) => {
        console.log('accepting consent');
        return this.http.patch<any>(
          environment.NEW_API + '/requests/' + action.id + '/informedConsent',
          {
            accepted: true,
            otp: action.otp,
          }
        );
      }),
      map((res) => {
        console.log('res from effects', JSON.stringify(res));
        if (res) {
          return ConsensoActions.acceptConsentSuccess({
            data: res,
          });
        } else {
          return ConsensoActions.acceptConsentFail({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while accepting consent:', error);
        return of(
          ConsensoActions.acceptConsentFail({
            error: error.error.code,
          })
        );
      })
    );
  });

  acceptConsentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsensoActions.acceptConsentSuccess),
        map(() => {
          this.router.navigate(['/appuntamenti']);
        })
      ),
    { dispatch: false }
  );
}
