import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Item } from 'src/app/model/DoctorTriage';
import { TriageDetailData } from 'src/app/model/TriageDetail';
import { ThisReceiver } from '@angular/compiler';
@Component({
  selector: 'app-single-anamnesi',
  templateUrl: './single-anamnesi.component.html',
  styleUrls: ['./single-anamnesi.component.scss'],
})
export class SingleAnamnesiComponent implements OnInit {
  @Input() singleAnamnesi: Item | null;
  public anamnesi: TriageDetailData;
  //   public isLoading: boolean = true;
  public isLoading: boolean = false;
  public openPanel: boolean = false;
  public isActived: boolean | undefined = false;
  @Input() status: boolean = false;
  public statusChanged: boolean = false;

  constructor(private http: HttpClient) {}
  ngOnInit(): void {
    this.isActived = this.singleAnamnesi?.active
    console.log('qui',this.singleAnamnesi)
  }

  activeAnamnesi(event: Event, id: string) {
    console.log((event.target as HTMLInputElement).checked);
    this.status = !(event.target as HTMLInputElement).checked;
    let items = [{ ...this.singleAnamnesi }];
    items[0].active = !this.status
    console.log('chiamata put',items)
    return this.http
      .put(`${environment.NEW_API}/profile/triage-info`, { items })
    .subscribe(data =>console.log(data))
  }

  getAnamnesi(id: string) {
    this.openPanel = !this.openPanel;
    this.isLoading = true;
    return this.http
      .get(`${environment.NEW_API}/profile/triage-detail/${id}`)
      .subscribe((data: any) => {
        console.log('single triage:', data.data);
        this.isLoading = false;
        return (this.anamnesi = data.data);
      });
  }
}
