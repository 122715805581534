import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as MedicProfileActions from './medic-profile.actions';

@Injectable()
export class MedicProfileEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  fetchMedicProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicProfileActions.fetchMedicProfile),
      switchMap((action) => {
        console.log('fetching medic profile');
        return this.http.get<any>(
          environment.NEW_API + '/doctors/' + action.medicId
        );
      }),
      map((medic) => {
        console.log('Medic profile from effects', medic);
        if (medic) {
          return MedicProfileActions.fetchMedicProfileSuccess({
            medic: medic.data,
          });
        } else {
          return MedicProfileActions.fetchMedicProfileFailure({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          MedicProfileActions.fetchMedicProfileFailure({
            error: error,
            //   'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );
}
