import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToAppuntamenti = () => redirectLoggedInTo(['chat']);

const routes: Routes = [
  {
    path: 'anamnesi',
    loadChildren: () =>
      import('./features/anamnesi/anamnesi.module').then(
        (m) => m.AnamnesiModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/features/login/login.module').then((m) => m.LoginModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToAppuntamenti },
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/features/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  //   {
  //     path: 'prenotazione',
  //     loadChildren: () =>
  //       import('./features/prenotazione/prenotazione.module').then(
  //         (m) => m.PrenotazioneModule
  //       ),
  //   },
  {
    path: 'richiesta',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'consenso',
    loadChildren: () =>
      import('./features/consenso/consenso.module').then(
        (m) => m.ConsensoModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'appuntamenti',
    loadChildren: () =>
      import('./features/richieste/richieste.module').then(
        (m) => m.RichiesteModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'storico',
    loadChildren: () =>
      import('./features/storico/storico.module').then((m) => m.StoricoModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'detail',
    loadChildren: () =>
      import('./features/detail/detail.module').then((m) => m.DetailModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'profilo-paziente',
    loadChildren: () =>
      import('./features/profilo-paziente/profilo-paziente.module').then(
        (m) => m.ProfiloPazienteModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'profilo',
    loadChildren: () =>
      import('./features/profilo/profilo.module').then((m) => m.ProfiloModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'patients',
    loadChildren: () =>
      import('./features/hm/patients/patients.module').then(
        (m) => m.PatientsModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./features/chat-page/chat-page.module').then(
        (m) => m.ChatPageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'assistenza',
    loadChildren: () =>
      import('./features/assistenza/assistenza.module').then(
        (m) => m.AssistenzaModule
      ),
  },
  {
    path: 'medic',
    loadChildren: () =>
      import('./features/medic-profile/medic-profile.module').then(
        (m) => m.MedicProfileModule
      ),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/features/signup/signup.module').then(
        (m) => m.SignupModule
      ),
  },
  {
    path: 'loading',
    loadChildren: () =>
      import('./features/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'cookiepolicy',
    loadChildren: () =>
      import('./features/cookie-policy/cookie-policy.module').then(
        (m) => m.CookiePolicyModule
      ),
  },
  {
    path: 'terminiecondizioni',
    loadChildren: () =>
      import('./features/termini-condizioni/termini-condizioni.module').then(
        (m) => m.TerminiCondizioniModule
      ),
  },
  {
    path: 'privacypolicy',
    loadChildren: () =>
      import('./features/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import(
        './features/prenotazione/trattamento-dati/trattamento-dati.module'
      ).then((m) => m.TrattamentoDatiModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
