<mat-nav-list *ngIf="auth">
  <a mat-list-item routerLink="/richieste" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>event_available</mat-icon><span class="nav-caption">Appuntamenti</span>
  </a>
  <a mat-list-item routerLink="/storico" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon><span class="material-symbols-outlined">history</span></mat-icon><span class="nav-caption">Storico</span>
  </a>
  <a mat-list-item routerLink="/assistenza" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>contact_support</mat-icon><span class="nav-caption">Centro assistenza</span>
  </a>
</mat-nav-list>
<mat-nav-list *ngIf="!auth">
  <a mat-list-item href="https://drfeel.com" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon><span class="nav-caption">Home </span>
  </a>
  <a mat-list-item routerLink="/login" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>account_circle</mat-icon><span class="nav-caption">Accedi </span>
  </a>
</mat-nav-list>
