import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as HmActions from './hm.actions';
import { of, pipe } from 'rxjs';
import { Patient } from 'src/app/model/Patient';
import {
  fetchRichiestaDetailsFailure,
  fetchRichiestaDetailsSuccess,
  fetchRichiestaDocumentsFailed,
  fetchRichiestaDocumentsSuccess,
} from '../../richieste/store/richieste.actions';

@Injectable()
export class HmEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  fetchPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HmActions.fetchPatients),
      switchMap((action) => {
        console.log('fetching patients');
        return this.fetchPatients(action);
      }),
      pipe(
        map((patients) => {
          console.log('patients from effects', patients);
          if (patients) {
            return HmActions.fetchPatientsSuccess({
              patients: patients,
            });
          } else {
            return HmActions.fetchPatientsFailure({
              error: 'error: ',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching profilo paziente', error);
          return of(
            HmActions.fetchPatientsFailure({
              error: 'errore',
            })
          );
        })
      )
    )
  );

  fetchPatients(action: any) {
    let url = environment.NEW_API + '/hm/patients';
    let searchValue: any = {
      sort: [
        {
          direction: 'DESC',
          field: 'modifiedtime',
        },
      ],
    };
    if (action.firstName || action.lastName || action.id) {
      searchValue = {
        filter: {
          filterBy: {
            operation: 'AND',
            filters: [],
          },
        },
      };

      if (action.firstName) {
        searchValue.filter.filterBy.filters.push({
          field: 'firstName',
          value: action.firstName,
          operation: 'LIKE',
        });
      }

      if (action.lastName) {
        searchValue.filter.filterBy.filters.push({
          field: 'lastName',
          value: action.lastName,
          operation: 'LIKE',
        });
      }

      if (action.id) {
        searchValue.filter.filterBy.filters.push({
          field: 'crmNo',
          value: action.id,
          operation: 'LIKE',
        });
      }
    }
    if (action.page) {
      searchValue = {
        ...searchValue,
        pagination: {
          page: action.page,
          size: 20,
        },
      };
    }
    const base64 = btoa(JSON.stringify(searchValue));
    url += '?searchRequest=' + base64;
    console.log('fetching patients');
    return this.http.get<Patient>(url);
  }

  fetchRichiestaDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HmActions.fetchHmRequestDetail),
      switchMap((action) => {
        console.log('fetching richiesta details');
        return this.http.get<any>(
          environment.NEW_API + '/hm/requests/' + action.richiestaId
        );
      }),
      pipe(
        map((richiesta) => {
          console.log('richiesta details from effects', richiesta);
          if (richiesta) {
            return fetchRichiestaDetailsSuccess({
              richiesta: richiesta.data,
            });
          } else {
            return fetchRichiestaDetailsFailure({
              error: 'error: Fetch details failed',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching:', error);
          return of(
            fetchRichiestaDetailsFailure({
              error:
                'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
            })
          );
        })
      )
    )
  );

  fetchHmRichiestaDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HmActions.fetchHmRequestDocuments),
      switchMap((action) =>
        this.http
          .get<any>(
            environment.NEW_API +
              '/hm/requests/' +
              action.richiestaId +
              '/documents'
          )
          .pipe(
            map((documents) => {
              console.log(
                'richiesta documents from effects',
                JSON.stringify(documents)
              );
              if (documents) {
                return fetchRichiestaDocumentsSuccess({
                  documents: documents.data,
                });
              } else {
                return fetchRichiestaDocumentsFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                fetchRichiestaDocumentsFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );
}
