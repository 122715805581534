import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fatturazione',
  templateUrl: './fatturazione.component.html',
  styleUrls: ['./fatturazione.component.scss']
})
export class FatturazioneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
