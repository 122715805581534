<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<ng-container *ngIf="kpiUrl">
  <div class="health-score">
    <img [src]="kpiUrl" alt="health score" />
  </div>
</ng-container>
<div class="plus-button" *ngIf="isHm" (click)="onClick()">
  <div class="btn do3-secondary-btn red">Upload MyHealth</div>
  <input
    type="file"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    multiple="multiple"
    accept="image/*"
    style="display: none"
  />
</div>

<app-kpi-input
  *ngIf="!env.production"
  [patientId]="patientId"
  [isHm]="isHm"
></app-kpi-input>

<app-surveys [pazienteId]="patientId" [isLoading]="loading"></app-surveys>
