<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<ng-container *ngIf="!loading">
  <form
    class="input-container container"
    (ngSubmit)="onSubmit()"
    #kpiForm="ngForm"
  >
    <div class="my-2">
      <label for="myHealthScore">MyHealth Score complessivo</label>
      <input
        type="text"
        id="myHealthScore"
        name="myHealthScore"
        placeholder="Inserisci dato"
        [(ngModel)]="myHealthScore"
        required
        [disabled]="!isHm"
      />
    </div>

    <div class="d-flex">
      <div class="mr-3">
        <label for="health">Health</label>
        <input
          type="text"
          id="health"
          name="health"
          placeholder="Inserisci dato"
          [(ngModel)]="health"
          required
          [disabled]="!isHm"
        />
      </div>
      <div class="mr-3">
        <label for="mind">Mind</label>
        <input
          type="text"
          id="mind"
          name="mind"
          placeholder="Inserisci dato"
          [(ngModel)]="mind"
          required
          [disabled]="!isHm"
        />
      </div>
      <div class="mr-3">
        <label for="movement">Movement</label>
        <input
          type="text"
          id="movement"
          name="movement"
          placeholder="Inserisci dato"
          [(ngModel)]="movement"
          required
          [disabled]="!isHm"
        />
      </div>
      <div class="mr-3">
        <label for="nutrition">Nutrition</label>
        <input
          type="text"
          id="nutrition"
          name="nutrition"
          placeholder="Inserisci dato"
          [(ngModel)]="nutrition"
          required
          [disabled]="!isHm"
        />
      </div>
    </div>

    <button *ngIf="isHm" type="submit" [disabled]="!kpiForm.valid">
      Upload Punteggio
    </button>
  </form>
</ng-container>
