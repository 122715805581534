import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Item } from 'src/app/model/DoctorTriage';

@Component({
  selector: 'app-anamnesi-list',
  templateUrl: './anamnesi-list.component.html',
  styleUrls: ['./anamnesi-list.component.scss'],
})
export class AnamnesiListComponent implements OnInit, OnDestroy {
  @Input() specializzazioni: Item[];
  @Input() isLoading: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
