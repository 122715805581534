import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as ProfiloPazienteActions from './profilo-paziente.actions';
import { of, pipe } from 'rxjs';
import { ProfiloPaziente } from 'src/app/model/ProfiloPaziente';
import { ProfiloDocument } from 'src/app/model/ProfiloDocument';
import { Richiesta } from 'src/app/model/Richiesta';
import { PatientMeasurement } from 'src/app/model/PatientMeasurement';

@Injectable()
export class ProfiloPazienteEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  fetchProfiloPaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchProfiloPaziente),
      switchMap((action) => {
        console.log('fetching profilo paziente');
        return this.http.get<ProfiloPaziente>(
          environment.NEW_API + '/patients/' + action.pazienteId
        );
      }),
      pipe(
        map((patient) => {
          console.log('profilo paziente from effects', patient);
          if (patient) {
            return ProfiloPazienteActions.fetchProfiloPazienteSuccess({
              profiloPaziente: patient.data,
            });
          } else {
            return ProfiloPazienteActions.fetchProfiloPazienteFailure({
              error: 'error: ',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching profilo paziente', error);
          return of(
            ProfiloPazienteActions.fetchProfiloPazienteFailure({
              error: 'errore',
            })
          );
        })
      )
    )
  );

  fetchMeasurementsPaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchProfiloMeasurementsPaziente),
      switchMap((action) => {
        const url = action.isHm
          ? `${environment.NEW_API}/hm/patients/${action.pazienteId}/measurement`
          : `${environment.NEW_API}/patients/${action.pazienteId}/measurement`;
        return this.http.get<PatientMeasurement>(url);
      }),
      pipe(
        map((patient) => {
          console.log('misurazioni paziente from effects', patient);
          if (patient) {
            return ProfiloPazienteActions.fetchProfiloMeasurementsPazienteSuccess(
              {
                measurements: patient.data,
              }
            );
          } else {
            return ProfiloPazienteActions.fetchProfiloMeasurementsPazienteFailure(
              {
                error: 'error: ',
              }
            );
          }
        }),
        catchError((error) => {
          console.error('Error while fetching measurements paziente', error);
          return of(
            ProfiloPazienteActions.fetchProfiloMeasurementsPazienteFailure({
              error: 'errore',
            })
          );
        })
      )
    )
  );

  fetchRichiestePaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchRichiestePaziente),
      switchMap((action) => {
        return this.getRichieste(action);
      }),
      pipe(
        map((anamnesi) => {
          console.log('anamnesi profilo from effect', anamnesi);

          return ProfiloPazienteActions.fetchRichiestePazienteSuccess({
            richieste: anamnesi.data,
          });
        })
      )
    )
  );

  getRichieste(action: any) {
    let url = !action.isHm
      ? environment.NEW_API + '/requests'
      : environment.NEW_API + '/hm/requests';
    let searchAnam;
    if (action.withTriage) {
      searchAnam = {
        filter: {
          filterBy: {
            operation: 'AND',
            filters: [
              {
                field: 'patient.id',
                operation: 'EQ',
                value: action.idPatient,
              },
              {
                field: 'needTriage',
                operation: 'EQ',
                value: true,
              },
              {
                field: 'hasTriage',
                operation: 'EQ',
                value: true,
              },
            ],
          },
        },
        pagination: {
          page: 0,
          size: 20,
        },
      };
    } else if (action.surveyRequest) {
      searchAnam = {
        filter: {
          filterBy: {
            operation: 'AND',
            filters: [
              {
                field: 'patient.id',
                operation: 'EQ',
                value: action.idPatient,
              },
              {
                field: 'surveyRequest',
                operation: 'EQ',
                value: true,
              },
            ],
          },
        },
        pagination: {
          page: 0,
          size: 20,
        },
      };
    } else {
      searchAnam = {
        filter: {
          filterBy: {
            operation: 'AND',
            filters: [
              {
                field: 'patient.id',
                operation: 'EQ',
                value: action.idPatient,
              },
            ],
          },
        },
        pagination: {
          page: 0,
          size: 20,
        },
      };
    }
    const base64 = btoa(JSON.stringify(searchAnam));
    url += '?searchRequest=' + base64;
    console.log('fetching all richieste profilo paziente', url);
    return this.http.get<Richiesta>(url);
  }

  fetchDocumentsPaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchDocumentsPaziente),
      switchMap((action) => {
        const url = action.isHm
          ? `${environment.NEW_API}/hm/patients/${action.pazienteId}/documents`
          : `${environment.NEW_API}/patients/${action.pazienteId}/documents`;
        console.log('fetching profilo');
        return this.http.get<ProfiloDocument>(url);
      }),
      map((docs) => {
        console.log('all profilo documents from effects', docs);
        if (docs) {
          return ProfiloPazienteActions.fetchDocumentsPazienteSuccess({
            documents: docs.data,
          });
        } else {
          return ProfiloPazienteActions.fetchDocumentsPazienteFail({
            error: 'error: ',
          });
        }
      })
    )
  );
}
