import { createAction, props } from '@ngrx/store';
import { MeData } from 'src/app/model/Me';
import { UserInfo } from 'src/app/model/UserInfo';

export const setMe = createAction(
  '[Home] Set Me',
  props<{
    me: MeData;
  }>()
);

export const updateMe = createAction(
  '[Home] Update Me',
  props<{
    me: MeData;
  }>()
);

export const fetchMeFail = createAction(
  '[Home] Fetch Me Fail',
  props<{
    error: any;
  }>()
);

export const fetchMe = createAction('[Home] Fetch Me');

export const fetchUserInfo = createAction('[Home] Fetch User Info');

export const fetchUserInfoFail = createAction(
  '[Home] Fetch User Info Fail',
  props<{
    error: any;
  }>()
);

export const fetchUserInfoSuccess = createAction(
  '[Home] Fetch User Info Success',
  props<{
    userInfo: UserInfo;
  }>()
);
