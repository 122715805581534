import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Richiesta,
  RichiestaDatum,
  RichiesteMeta,
} from 'src/app/model/Richiesta';

@Component({
  selector: 'app-appuntamenti-list',
  templateUrl: './appuntamenti-list.component.html',
  styleUrls: ['./appuntamenti-list.component.scss'],
})
export class AppuntamentiListComponent implements OnInit {
  @Input() richieste: Richiesta;
  @Input() listType: 'today' | 'future' | 'past' | 'all' | 'open' | 'closed' =
    'today';
  @Input() mode: 'appuntamenti' | 'storico';
  @Input() page: number = 0;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  public dateDisplay: any;
  public richiesteData: RichiestaDatum[] = [];
  public richiesteMeta: RichiesteMeta;
  public pageDirection: string;

  constructor() {}

  ngOnInit(): void {
    this.richiesteData = this.richieste.data;
    this.richiesteMeta = this.richieste.meta;
    switch (this.listType) {
      case 'today':
        this.dateDisplay = new Date();
        break;

      case 'future':
        this.dateDisplay = 'Appuntamenti in programma';
        break;

      default:
        break;
    }
  }

  handleDetailClick(richiesta: RichiestaDatum) {
    this.onClick.emit(richiesta);
  }

  handlePageClick(page: any) {
    this.page = page;
    this.changePage.emit(this.page);
  }
}
