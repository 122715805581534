import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input/text-input.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ButtonShadowComponent } from './button-shadow/button-shadow.component';
import { ButtonPrenotaComponent } from './button-prenota/button-prenota.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    TextInputComponent,
    ButtonShadowComponent,
    ButtonPrenotaComponent,
  ],
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatButtonModule,MatIconModule],
  exports: [TextInputComponent, ButtonShadowComponent, ButtonPrenotaComponent],
})
export class UIModule {}
