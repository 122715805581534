import { createAction, props } from '@ngrx/store';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import { RichiestaDetailDatum } from 'src/app/model/RichiestaDetail';

export const fetchAllRichieste = createAction(
  '[Richieste] Fetch Richieste',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: any;
    page?: any;
  }>()
);

export const fetchAllRichiesteSuccess = createAction(
  '[Richieste] Fetch Richieste Success',
  props<{ richieste: Richiesta }>()
);

export const fetchAllRichiesteFailure = createAction(
  '[Richieste] Fetch Richieste Failure',
  props<{ error: any }>()
);

export const fetchTodayRequests = createAction(
  '[Richieste] Fetch Today Requests',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: any;
    page?: any;
  }>()
);

export const fetchTodayRequestsSuccess = createAction(
  '[Richieste] Fetch Today Requests Success',
  props<{ richieste: Richiesta }>()
);

export const fetchTodayRequestsFailure = createAction(
  '[Richieste] Fetch Today Requests Failure',
  props<{ error: any }>()
);

export const fetchFutureRequests = createAction(
  '[Richieste] Fetch Future Requests',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: any;
    page?: any;
  }>()
);

export const fetchFutureRequestsSuccess = createAction(
  '[Richieste] Fetch Future Requests Success',
  props<{ richieste: Richiesta }>()
);

export const fetchFutureRequestsFailure = createAction(
  '[Richieste] Fetch Future Requests Failure',
  props<{ error: any }>()
);

export const fetchClosedRequests = createAction(
  '[Richieste] Fetch Closed Requests',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: any;
    page?: any;
  }>()
);

export const fetchClosedRequestsSuccess = createAction(
  '[Richieste] Fetch Closed Requests Success',
  props<{ richieste: Richiesta }>()
);

export const fetchClosedRequestsFailure = createAction(
  '[Richieste] Fetch Closed Requests Failure',
  props<{ error: any }>()
);

export const fetchPastOpenRequests = createAction(
  '[Richieste] Fetch Past Open Requests',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: any;
    page?: any;
  }>()
);

export const fetchPastOpenRequestsSuccess = createAction(
  '[Richieste] Fetch Past Open Requests Success',
  props<{ richieste: Richiesta }>()
);

export const fetchPastOpenRequestsFailure = createAction(
  '[Richieste] Fetch Past Open Requests Failure',
  props<{ error: any }>()
);

export const fetchRichiesteDetails = createAction(
  '[Richieste] Fetch Richieste Details',
  props<{ richiestaId: string }>()
);

export const fetchRichiestaDetailsSuccess = createAction(
  '[Richieste] Fetch Richiesta Details Success',
  props<{ richiesta: RichiestaDetailDatum }>()
);

export const fetchRichiestaDetailsFailure = createAction(
  '[Richieste] Fetch Richiesta Details Failure',
  props<{ error: any }>()
);

export const deleteRichiesta = createAction(
  '[Richieste] Delete Richiesta',
  props<{ id: string }>()
);

export const deleteRichiestaSuccess = createAction(
  '[Richieste] Delete Richiesta Success',
  props<{ id: string }>()
);

export const deleteRichiestaFailed = createAction(
  '[Richieste] Delete Richiesta Failed'
);

export const fetchTriage = createAction(
  '[Richieste] Fetch Triage',
  props<{ richiestaId: string }>()
);

export const fetchTriageSuccess = createAction(
  '[Richieste] Fetch Triage Success',
  props<{ triage: any }>()
);

export const fetchTriageFailed = createAction(
  '[Richieste] Fetch Triage Failed',
  props<{ error: any }>()
);

export const postTriage = createAction(
  '[Richieste] Post Triage',
  props<{ triage: any; richiestaId: string }>()
);

export const postTriageSuccess = createAction(
  '[Richieste] Post Triage Success',
  props<{ triage: any }>()
);

export const postTriageFailed = createAction(
  '[Richieste] Post Triage Failed',
  props<{ error: any }>()
);

export const fetchRichiestaDocuments = createAction(
  '[Richieste] Fetch Richiesta Documents',
  props<{ richiestaId: string }>()
);

export const fetchRichiestaDocumentsSuccess = createAction(
  '[Richieste] Fetch Richiesta Documents Success',
  props<{ documents: any }>()
);

export const fetchRichiestaDocumentsFailed = createAction(
  '[Richieste] Fetch Richiesta Documents Failed',
  props<{ error: any }>()
);

export const fetchRichiestaMeeting = createAction(
  '[Richieste] Fetch Richiesta Meeting',
  props<{ richiestaId: string }>()
);

export const fetchRichiestaMeetingSuccess = createAction(
  '[Richieste] Fetch Richiesta Meeting Success',
  props<{ meeting: any }>()
);

export const fetchRichiestaMeetingFailed = createAction(
  '[Richieste] Fetch Richiesta Meeting Failed',
  props<{ error: any }>()
);

export const fetchCalendarRequests = createAction(
  '[Richieste] Fetch Calendar Request',
  props<{
    fromDate?: string;
    toDate?: string;
    status?: string;
    page?: number;
    pageSize?: number;
  }>()
);

export const fetchCalendarRequestsSuccess = createAction(
  '[Richieste] Fetch Calendar Request Success',
  props<{ requests: any }>()
);

export const fetchCalendarRequestsFailed = createAction(
  '[Richieste] Fetch Calendar Request Failed',
  props<{ error: any }>()
);

export const closeRequest = createAction(
  '[Richieste] Close Request',
  props<{ requestId: string }>()
);

export const closeRequestSuccess = createAction(
  '[Richieste] Close Request Success'
);

export const closeRequestFailed = createAction(
  '[Richieste] Close Request Failed',
  props<{ error: any }>()
);

export const postNote = createAction(
  '[Richieste] Post Note',
  props<{ requestId: string; closeRequest: boolean; note: string }>()
);

export const postNoteSuccess = createAction(
  '[Richieste] Post Note Success',
  props<{ note: string }>()
);

export const postNoteFailed = createAction(
  '[Richieste] Post Note Failed',
  props<{ error: any }>()
);

export const myWellnessInvite = createAction(
  '[Richieste] myWellness Invite',
  props<{ patientId: string }>()
);

export const myWellnessInviteSuccess = createAction(
  '[Richieste] myWellness Invite Success',
  props<{ res: any }>()
);

export const myWellnessInviteFailed = createAction(
  '[Richieste] myWellness Invite Failed',
  props<{ error: any }>()
);

export const movergyDisplay = createAction(
  '[Richieste] Movergy Display',
  props<{ patientId: string }>()
);

export const movergyDisplaySuccess = createAction(
  '[Richieste] Movergy Display Success',
  props<{ movergyDisplay: any }>()
);

export const movergyDisplayFailed = createAction(
  '[Richieste] Movergy Display Failed',
  props<{ error: any }>()
);

export const resetRichieste = createAction('[Richieste] Reset Richieste');
